import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UiTranslateModule } from '@fcom/ui-translate';

import { IconsModule } from '../icons';
import { NotificationsModule } from '../notifications/notifications.module';
import { ImagesModule } from '../images/images.module';
import { ButtonModule } from '../buttons/button.module';
import { ProductCardComponent } from './product-card/product-card.component';
import { TagModule } from '../tag';

@NgModule({
  declarations: [ProductCardComponent],
  imports: [CommonModule, IconsModule, ImagesModule, ButtonModule, NotificationsModule, TagModule, UiTranslateModule],
  exports: [ProductCardComponent],
})
export class ProductsModule {}
