<section
  (click)="ctaTrigger$.next(true)"
  [class.promo-cta]="theme !== PromoTheme.FULL_BG && ctaData$ | async"
  class="promo-card relative fill grow no-underline overflow-hidden flex flex--column-reverse"
  [class.white-bg]="theme === PromoTheme.WHITE"
  [class.nordic-blue-900-bg]="theme === PromoTheme.BLUE"
  [class.black-bg]="theme === PromoTheme.BLACK"
  [class.white-text]="theme !== PromoTheme.WHITE"
  [class.nordic-blue-900-text]="theme === PromoTheme.WHITE"
  [class.border]="
    theme === PromoTheme.WHITE && !(theme === PromoTheme.WHITE && (imageData$ | async) !== null && !isDouble)
  "
  data-testid="promo-card-container"
>
  <section
    *ngIf="data$ | async as article"
    class="ps-medium no-decoration fill"
    data-testid="promo-card-text-container"
    [class.z1]="isTextOnTop"
  >
    <div class="flex mr-clear-y ps-medium-b promo-card-heading" data-testid="promo-card-heading">
      <fcom-icon
        *ngIf="article?.localSettings?.iconName"
        class="icon-medium ms-xsmall-r"
        [category]="article?.localSettings?.iconCategory ?? IconLibrary.SVG_LIBRARY"
        [name]="article?.localSettings?.iconName"
      ></fcom-icon>
      <h3 class="mr-clear-y" [class.white-text]="theme !== PromoTheme.WHITE">{{ article.title }}</h3>
    </div>
    <span [innerHTML]="article.teaserText"></span>

    <div *ngIf="ctaData$ | async" class="flex flex--end ps-medium-t">
      <fcom-button
        [text]="ctaData$ | finMap: 'title' | async"
        [theme]="
          PromoTheme.WHITE === theme
            ? ButtonTheme.LIGHT_PRIMARY_NORDIC_BLUE
            : PromoTheme.FULL_BG === theme
              ? ButtonTheme.DARK_PRIMARY_NORDIC_BLUE
              : ButtonTheme.LIGHT_PRIMARY_CTA_HEATHER
        "
      ></fcom-button>
    </div>
  </section>
  <ng-container *ngIf="imageData$ | async as imageData">
    <section
      [class.full-image]="theme === PromoTheme.FULL_BG"
      [class.promo-image]="theme !== PromoTheme.FULL_BG"
      class="relative fill grow overflow-hidden"
    >
      <fcom-image
        *ngIf="!imageData.error"
        class="fill"
        [fillParent]="true"
        [imageData]="imageData"
        [alt]="imageData?.alt"
        [ratio]="aspectRatio"
        [scale]="true"
      >
      </fcom-image>
    </section>
  </ng-container>
</section>
