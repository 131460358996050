import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { Observable, of, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { unsubscribe } from '@fcom/core/utils/unsubscribe';
import { Airport } from '@fcom/common/interfaces/booking';
import { ConfigService } from '@fcom/core/services';

/**
 * @Unstable
 * Warning! This component is in active development and is not production ready.
 *
 * Documentation can be found in `storybook/stories/flights/unified-flight-object.stories.mdx`
 */
@Component({
  selector: 'fcom-ufo',
  templateUrl: 'unified-flight-object.component.html',
  styleUrls: ['./unified-flight-object.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnifiedFlightObjectComponent implements OnInit, OnDestroy {
  /**
   * Object containing the information for the Departure airport, including
   * - city name
   * - airport name
   * - airport code
   * - formatted dateTime of departure @example "08:45 Thu"
   *
   * Required
   */
  @Input({ required: true })
  departure: Airport;

  /**
   * Object containing the information for the Arrival airport, including
   * - city name
   * - airport name
   * - airport code
   * - formatted dateTime of arrival @example "08:45 Thu"
   *
   * Required
   */
  @Input({ required: true })
  arrival: Airport;

  /**
   * An array of tails for the bound, these should follow the format of "tail_ay" and will be
   * loaded from @finnairoyj/fcom-ui-styles. So you can check the available tails there.
   */
  @Input()
  tails: string[] = [];

  /**
   * Formatted string showing the duration of the flight
   */
  @Input()
  duration = '';

  /**
   * An array of airline names
   */
  @Input()
  airlines: string[] = [];

  /**
   * Number of stops on the flight. Used for determining "Direct", "1 stop", "2 stops" text.
   */
  @Input()
  stops = 0;

  /**
   * Boolean indicating if the view Details button should be shown
   */
  @Input()
  showViewDetails = true;

  /**
   * Boolean indicating if the bound is a bus connection
   */
  @Input()
  isByBusOnly = false;

  @Input()
  focusOnViewDetails$: Observable<boolean> = of(false);

  /**
   * Formatted string showing the flight numbers
   */

  @Input()
  flightNumbers = '';

  @Input()
  viewDetailsBtnKey = 'manageMyBooking.widget.viewFullOrder';

  /**
   * Output event emitter used to indicate when the view Details button has been clicked.
   * This should be listened to for determining if a modal should be displayed.
   */
  @Output()
  viewDetails = new EventEmitter<MouseEvent>();

  @ViewChild('viewDetailsBtn', { static: false, read: ElementRef }) viewDetailsBtn: ElementRef;

  showFlightNumber = false;
  private subscriptions: Subscription = new Subscription();

  constructor(configService: ConfigService) {
    this.showFlightNumber = configService.cfg.enableShowFlightNumbers;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.focusOnViewDetails$
        .pipe(filter((focus) => this.showViewDetails && focus))
        .subscribe(() => setTimeout(() => this.viewDetailsBtn.nativeElement.focus(), 0))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }
}
