<ng-container
  [ngTemplateOutlet]="bookingHeaderSummary"
  [ngTemplateOutletContext]="{
    showPopover: true,
  }"
></ng-container>

<ng-template #bookingHeaderSummary let-showPopover="showPopover">
  <div
    class="flex flex--middle summary-wrapper"
    [ngClass]="{ 'flex--space-between': isMobile$ | async, 'ps-xsmall-y': (enableModifySearch$ | async) === false }"
  >
    <fin-booking-header-summary
      [bookingSummary]="bookingSummary"
      (modifySearch)="modifySearchDetails()"
      [isClickable]="enableModifySearch$ | async"
    ></fin-booking-header-summary>

    <ng-container *ngIf="enableModifySearch$ | async">
      @if (
        bookingWidgetPromoEnabled &&
        showPopover &&
        !((isOpenWidgetByDefaultTest$ | async) && (isMobile$ | async) === false)
      ) {
        <div
          fcomPopover
          [content]="popoverContent"
          [options]="tooltipOptions$ | async"
          (closeClick)="bookingWidgetPromoClosed()"
          data-testid="widgetPromo"
        >
          <ng-container *ngTemplateOutlet="modifySearchButton"></ng-container>
        </div>
      } @else {
        <ng-container *ngTemplateOutlet="modifySearchButton"></ng-container>
      }
    </ng-container>
  </div>
</ng-template>

<ng-template #modifySearchButton>
  @if (isMobile$ | async) {
    <fcom-icon-button
      [theme]="IconButtonTheme.DARK_SECONDARY_OUTLINE"
      [icon]="{
        category: IconLibrary.SVG_LIBRARY,
        name: (expand.isOpen$ | async) ? SvgLibraryIcon.CLOSE_DELETE : SvgLibraryIcon.EDIT,
      }"
      [aria]="{
        label: ((expand.isOpen$ | async) ? 'actions.close' : 'bookingWidget.modifySearch') | finLocalization,
      }"
      (click)="toggleDetails()"
      data-testid="global-booking-widget-header-modify"
    >
    </fcom-icon-button>
  } @else {
    <fcom-button
      [icon]="{
        category: IconLibrary.SVG_LIBRARY,
        name: (expand.isOpen$ | async) ? SvgLibraryIcon.CLOSE_DELETE : SvgLibraryIcon.EDIT,
        position: IconPosition.LEFT,
      }"
      [theme]="ButtonTheme.DARK_SECONDARY_OUTLINE"
      [type]="ButtonType.MENU"
      [text]="((expand.isOpen$ | async) ? 'actions.close' : 'bookingWidget.modifySearch') | finLocalization"
      class="pr-small-l"
      (click)="toggleDetails()"
      data-testid="global-booking-widget-header-modify"
    ></fcom-button>
  }
</ng-template>

<fin-expand
  [key]="EXPANDER_KEY"
  [id]="EXPANDER_KEY + '-' + ExpanderStatus.OPEN"
  [initiallyOpen]="false"
  [targetHeightElement]="TARGET_HEIGHT_ELEMENT.PARENT"
  [useCustomTrigger]="true"
  class="dn-mobile"
  #expand
>
  <div
    *ngIf="enableModifySearch$ | async"
    class="content ps-medium-t ps-xsmall-b nordic-blue-900-text grid-max-width center"
  >
    <fin-booking-widget-common
      global-booking-widget
      [layout]="WidgetLayout.HORIZONTAL"
      (changeGlobalBookingWidgetExpanderStatus)="dispatchExpanderStatus($event)"
      [usePopoverSelectors]="true"
      [isGlobalBookingWidget]="true"
    ></fin-booking-widget-common>
  </div>
</fin-expand>

<fcom-modal
  *ngIf="(isMobile$ | async) && (enableModifySearch$ | async)"
  [(open)]="modalOpen"
  [title]="'bookingWidget.modifySearch' | finLocalization"
  [buttons]="null"
  class="nordic-blue-900-bg"
  data-testid="global-booking-widget-header-mobile-modal"
>
  <div class="grow scroll nordic-blue-900-bg">
    <div class="content ps-medium-x ps-small-b ps-xxsmall-t nordic-blue-900-text">
      <ng-container
        [ngTemplateOutlet]="bookingHeaderSummary"
        [ngTemplateOutletContext]="{
          showPopover: false,
        }"
      ></ng-container>

      <fin-booking-widget-common
        global-booking-widget
        [layout]="WidgetLayout.HORIZONTAL"
        (changeGlobalBookingWidgetExpanderStatus)="dispatchExpanderStatus($event)"
        [usePopoverSelectors]="true"
        [isGlobalBookingWidget]="true"
      ></fin-booking-widget-common>
    </div>
  </div>
</fcom-modal>

<ng-template #popoverContent>
  <div class="ps-medium rounded-large">
    <header class="flex flex--space-between">
      <fcom-tag>{{ 'bookingWidget.globalWidgetPromo.tag' | finLocalization }}</fcom-tag>
    </header>
    <p class="font-body-1 ps-small-y">
      {{ 'bookingWidget.globalWidgetPromo.text' | finLocalization }}
    </p>
  </div>
</ng-template>
