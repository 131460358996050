import { Injectable, Pipe, PipeTransform } from '@angular/core';

import {
  FinnairItineraryItem,
  FinnairItineraryItemFlight,
  ItineraryItemFlight,
  ItineraryItemLayover,
} from '@fcom/dapi/api/models';

/**
 * Map all flight numbers of one bound.
 */
@Injectable()
@Pipe({
  name: 'finFlightNumbers',
  pure: true,
})
export class FlightNumbersPipe implements PipeTransform {
  transform(itinerary: (FinnairItineraryItem | ItineraryItemFlight | ItineraryItemLayover)[]): string {
    return (
      itinerary
        ?.map((i: FinnairItineraryItemFlight) => i?.flightNumber)
        .filter(Boolean)
        .join(', ') ?? ''
    );
  }
}
