import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { CmsContent, CmsTaxonomyGeneral } from '@fcom/core-api';

@Component({
  selector: 'fin-editorial-category',
  templateUrl: './editorial-category.component.html',
  styleUrls: ['./editorial-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorialCategoryComponent {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  editorialCategory: CmsContent;

  get segmentId(): string {
    if (this.editorialCategory.segment !== JSON.stringify(this.editorialCategory.contentId)) {
      return this.editorialCategory.segment;
    }
    return null;
  }

  isShowAllLink(article: CmsContent): boolean {
    return article.subjectTaxonomyTags.includes(CmsTaxonomyGeneral.ARROW_LINK);
  }
}
