import { LocalDate } from '@fcom/core/utils';

import { CalendarNavigationType, DateRange } from '../components/datepicker';

interface MonthInfo {
  id: string;
  year: number;
  value: number;
  i18n: number;
  weekDays: any;
  label: string;
}

export enum SpacerType {
  START,
  END,
}

export interface Week {
  days: (Day & UiDay)[];
}

export interface Month extends MonthInfo {
  days: Day[];
  monthArrayIndex?: number;
  weeks: Week[];
}

export interface Day {
  id: string;
  value: number;
  weekday: number;
  i18n: number;
  today: boolean;
  past: boolean;
  future: boolean;
  date: LocalDate;
  selected?: boolean;
  spacer?: number;
  spacerType?: SpacerType;
  disabled?: boolean;
  tabindex: -1 | 0;
  ariaLabel: string;
  focused: boolean;
}

export interface UiDay {
  reference?: Day;
  instance?: Day;
  label?: string;
}

export interface DateSelection {
  startDate: LocalDate;
  endDate?: LocalDate;
}

export type CalendarViewModel = {
  displayMonths: 1 | 2;
  isDateRange: boolean;
  scrollOnInit: boolean;
  showTags: boolean;
  minDate: LocalDate | null;
  maxDate: LocalDate | null;
  firstDate: LocalDate | null;
  lastDate: LocalDate | null;
  focusDate: LocalDate | null;
  selectedDates: DateSelection;
  isMobile: boolean;
  months: Month[];
  monthTags?: string[];
  navigationType: CalendarNavigationType;
  prevDisabled: boolean;
  nextDisabled: boolean;
  loadAmount: number;
  disabledDateRanges?: DateRange[];
};
