<div class="flex">
  <div class="flex-1 separator pr-small-r mr-small-r overflow-hidden">
    <fcom-timeline
      *ngIf="arrival && departure"
      [arrival]="arrival"
      [departure]="departure"
      [isByBusOnly]="isByBusOnly"
    ></fcom-timeline>
  </div>
  <div class="bound-details flex" [style.minWidth]="0">
    <div *ngIf="tails.length" class="flex--nogrow flex mr-xxsmall-r ms-xxsmall-b" aria-hidden="true">
      <div
        *ngFor="let tail of tails; let i = index"
        class="flex--nogrow tail {{ tail }}"
        [class.first]="i === 0"
        [class.minus-margin]="i !== 0"
      ></div>
    </div>

    <div class="font-body-2 flex flex--column grow">
      <div *ngIf="airlines.length > 0" class="flex--nogrow nordic-blue-900-text overflow-hidden two-lines-ellipsis">
        <span class="is-vishidden">
          {{ 'bookingSummary.operatedBy' | finLocalization }}
        </span>
        {{ airlines.join(', ') }}
      </div>

      <div
        data-testid="flight-numbers"
        *ngIf="showFlightNumber && flightNumbers.length > 0"
        class="truncate overflow-hidden font-body-2 nordic-blue-400-text ps-xxsmall-b"
      >
        {{ flightNumbers }}
      </div>

      <div *ngIf="duration || stops !== undefined" class="flex--nogrow flex flex--wrap overflow-hidden">
        <span class="truncate overflow-hidden bold-type" *ngIf="stops !== undefined">
          <ng-container
            >{{
              'flightItinerary.' + (stops === 0 ? 'direct' : 'stopOver.' + (stops === 1 ? 'singular' : 'plural'))
                | finLocalization: [stops]
            }}<span>&nbsp;</span></ng-container
          >
        </span>
        <span *ngIf="duration" class="truncate overflow-hidden">{{ duration }}</span>
      </div>

      <div *ngIf="showViewDetails" class="flex-2 flex flex--align-end ms-xxsmall-t">
        <!-- TODO: use global translation key for view Details instead of booking translation key -->
        <button
          class="font-body-2 nordic-blue-900-text blue-light-hover medium-type underline overflow-hidden align-left"
          #viewDetailsBtn
          data-testid="ufo-open-modal-button"
          (click)="viewDetails.emit($event)"
        >
          {{ viewDetailsBtnKey | finLocalization }}
        </button>
      </div>
    </div>
  </div>
</div>
