import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  HostListener,
  AfterViewInit,
  ViewChild,
} from '@angular/core';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import {
  Observable,
  of,
  Subject,
  distinctUntilChanged,
  filter,
  switchMap,
  EMPTY,
  delay,
  Subscription,
  debounceTime,
  withLatestFrom,
  BehaviorSubject,
  tap,
  map,
} from 'rxjs';
import { Store } from '@ngrx/store';

import { finShare } from '@fcom/rx';
import { MultivariateTestId, TARGET_HEIGHT_ELEMENT, TestVariant } from '@fcom/common';
import { MediaQueryService } from '@fcom/common/services';
import { BookingHeaderSummary } from '@fcom/common/interfaces';
import {
  ButtonType,
  ButtonSize,
  IconPosition,
  ButtonTheme,
  PopoverService,
  IconButtonSize,
  IconButtonTheme,
  PopoverOptions,
  PopoverPosition,
} from '@fcom/ui-components';
import { BookingAppState } from '@fcom/common/interfaces/booking';
import { PseudoLocalActions } from '@fcom/core/actions';
import { ExpandComponent } from '@fcom/common/components';
import { StorageService, isEmptyObjectOrHasEmptyValues, ConfigService } from '@fcom/core';
import { MultivariateTestService } from '@fcom/common/multivariate-test/services/multivariate-test.service';

import { BOOKING_WIDGET_CONTEXT, BOOKING_WIDGET_PROMO, GLOBAL_BOOKING_WIDGET_EXPANDER_KEY } from '../../constants';
import { WidgetLayout, ExpanderStatus } from '../../interfaces';
import { BookingWidgetGtmService } from '../../services/booking-widget-gtm.service';
import { BookingWidgetService } from '../../services/booking-widget.service';

@Component({
  selector: 'fin-global-booking-widget-summary-header',
  templateUrl: './global-booking-widget-summary-header.component.html',
  styleUrls: ['./global-booking-widget-summary-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalBookingWidgetSummaryHeaderComponent implements OnInit, AfterViewInit {
  readonly EXPANDER_KEY = 'global-booking-widget-expander';
  readonly TARGET_HEIGHT_ELEMENT = TARGET_HEIGHT_ELEMENT;
  readonly SCROLL_CLOSE_WIDGET_TIME_DELAY_SMALL = 550;
  readonly SCROLL_CLOSE_WIDGET_TIME_DELAY_MEDIUM = 500;
  readonly ButtonSize = ButtonSize;
  readonly IconLibrary = IconLibrary;
  readonly IconPosition = IconPosition;
  readonly ButtonTheme = ButtonTheme;
  readonly ButtonType = ButtonType;
  readonly ExpanderStatus = ExpanderStatus;
  readonly WidgetLayout = WidgetLayout;
  readonly IconButtonSize = IconButtonSize;
  readonly IconButtonTheme = IconButtonTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  tooltipOptions$: Observable<PopoverOptions>;
  isMobile$: Observable<boolean>;
  isOpenWidgetByDefaultTest$: Observable<boolean>;
  lastScrollTop: number = 0;
  scrollEvents$ = new Subject<Event>();
  subscription = new Subscription();
  isExpanderOpen$ = new BehaviorSubject<boolean>(false);
  bookingWidgetPromoEnabled = false;
  modalOpen = false;

  @Input() bookingSummary: BookingHeaderSummary;
  @Input() enableModifySearch$ = of(false);

  @ViewChild('expand') expand: ExpandComponent;

  @HostListener('window:scroll', ['$event.target'])
  onScroll(event: Event): void {
    this.scrollEvents$.next(event);
  }

  constructor(
    private mediaQueryService: MediaQueryService,
    private store$: Store<BookingAppState>,
    private popoverService: PopoverService,
    private bookingWidgetGtmService: BookingWidgetGtmService,
    private bookingWidgetService: BookingWidgetService,
    private storageService: StorageService,
    private multivariateTestService: MultivariateTestService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.isMobile$ = this.mediaQueryService.isBreakpoint$('mobile');
    this.bookingWidgetPromoEnabled = !this.storageService.LOCAL.get(BOOKING_WIDGET_PROMO);
    this.isOpenWidgetByDefaultTest$ = this.configService.cfg.enableBookingWidgetOpenByDefault
      ? this.multivariateTestService.getTestVariant(MultivariateTestId.OPEN_WIDGET).pipe(
          map((variant) => variant.variant === TestVariant.B),
          finShare()
        )
      : of(false);
    this.tooltipOptions$ = this.mediaQueryService.isBreakpoint$('tablet_down').pipe(
      map((isTabletDown) => ({
        context: BOOKING_WIDGET_PROMO,
        popoverID: 'globalWidgetPromo',
        zIndex: 4,
        showLeftBorder: false,
        shadow: true,
        alignToLeft: !isTabletDown,
        showCloseButton: true,
        disableAutoFocus: true,
        useFixedPosition: true,
        showArrowCaret: true,
        showFullscreenOnBreakpoint: undefined,
        roundedCorners: true,
        openByDefault: true,
        tooltipWidth: 360,
        popoverPosition: PopoverPosition.BOTTOM,
        hasPadding: false,
      }))
    );
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.expand.isOpen$
        .pipe(
          distinctUntilChanged(),
          tap((isOpen) => {
            this.isExpanderOpen$.next(isOpen);
          }),
          withLatestFrom(this.isOpenWidgetByDefaultTest$),
          switchMap(([isWidgetOpen, isOpenWidgetByDefaultTest]) =>
            isWidgetOpen
              ? this.scrollEvents$.pipe(
                  delay(
                    isOpenWidgetByDefaultTest
                      ? this.SCROLL_CLOSE_WIDGET_TIME_DELAY_SMALL
                      : this.SCROLL_CLOSE_WIDGET_TIME_DELAY_MEDIUM
                  ),
                  debounceTime(50)
                )
              : EMPTY
          ),
          withLatestFrom(this.isMobile$, this.bookingWidgetService.globalBookingWidgetSelectionChanges$),
          filter(([_, isMobile, hasChanges]) => !isMobile && isEmptyObjectOrHasEmptyValues(hasChanges))
        )
        .subscribe(([event]) => {
          this.handleScroll(event);
        })
    );

    this.subscription.add(
      this.expand.isOpen$.subscribe((isOpen) => {
        this.popoverService.closeByContext(isOpen ? BOOKING_WIDGET_PROMO : BOOKING_WIDGET_CONTEXT);
        this.modalOpen = isOpen;
      })
    );

    this.subscription.add(
      this.isOpenWidgetByDefaultTest$
        .pipe(
          filter(Boolean),
          switchMap(() => this.isMobile$.pipe(filter((isMobile) => !isMobile)))
        )
        .subscribe(() => {
          this.dispatchExpanderStatus(ExpanderStatus.OPEN);
        })
    );
  }

  dispatchExpanderStatus(expandStatus: ExpanderStatus): void {
    this.store$.dispatch(
      PseudoLocalActions.setValue({
        key: GLOBAL_BOOKING_WIDGET_EXPANDER_KEY,
        data: `${GLOBAL_BOOKING_WIDGET_EXPANDER_KEY}-${expandStatus}`,
      })
    );
  }

  modifySearchDetails(): void {
    if (!this.modalOpen) {
      this.bookingWidgetGtmService.trackElementEvent('global-widget-modify-search-details');
      this.dispatchExpanderStatus(ExpanderStatus.OPEN);
    }
  }

  toggleDetails(): void {
    this.storageService.LOCAL.set(BOOKING_WIDGET_PROMO, true);

    this.bookingWidgetGtmService.trackElementEvent(
      `global-widget-${this.isExpanderOpen$.getValue() ? 'cancel-search' : 'modify-search'}`
    );
    this.dispatchExpanderStatus(this.isExpanderOpen$.getValue() ? ExpanderStatus.CLOSE : ExpanderStatus.OPEN);
  }

  private handleScroll(event): void {
    const currentScrollTop = event.scrollingElement?.scrollTop ?? 0;
    if (currentScrollTop - this.lastScrollTop > 150) {
      this.dispatchExpanderStatus(ExpanderStatus.CLOSE);
    }
    this.lastScrollTop = currentScrollTop;
  }

  bookingWidgetPromoClosed(): void {
    this.storageService.LOCAL.set(BOOKING_WIDGET_PROMO, true);
  }
}
