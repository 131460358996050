import { HttpClient, HttpHeaders, type HttpResponse } from '@angular/common/http';
import { type ElementRef, Injectable } from '@angular/core';
import { FormBuilder, type FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, type UrlSegment } from '@angular/router';

import { Store } from '@ngrx/store';
import { combineLatest, type Observable, of, switchMap, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CorporateUserRole, type Profile } from '@fcom/core-api/login';
import { type AppState } from '@fcom/core/interfaces';
import { loginToken, profile } from '@fcom/core/selectors';
import { ConfigService, SentryLogger } from '@fcom/core/services';
import { EMAIL_REGEX, LocalDate, PHONE_PATTERN } from '@fcom/core/utils';
import { retryWithBackoff } from '@fcom/rx';
import { type AttachmentResponse } from '@fcom/ui-components/components/forms/interfaces';
import { LanguageService } from '@fcom/ui-translate';

import {
  Company,
  CompanyUpdate,
  type CorporateContactUs,
  CorporateSalesForceUser,
  CorporateUser,
  CorporateUserForm,
  EnrollCorporate,
  SelectOption,
  Data,
  DataRequest,
  DataResponse,
  TripsRequest,
  TripsResponse,
  type VatReceiptContactDetails,
  type VatReceiptResult,
  type VatReceiptSearch,
} from '../../interfaces';
import { ScrollService } from '../scroll/scroll.service';
import { finnairPlusNumberValidator } from '../../utils/form-validators';
import { fileToBase64 } from '../../utils/form-utils';

export const TERMS_AND_CONDITIONS_PREFIX = 'https://cdn.finnair.com/pdf/fcp/FCP_Terms_and_conditions_';

export const NAME_PATTERN = /^[a-z\-àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšž∂ð ]+$/i;
export const ZIP_CODE_PATTERN = /^[a-zA-Z0-9 -]*$/;

@Injectable()
export class CorporateService {
  apiUrl: string;

  constructor(
    private store$: Store<AppState>,
    private configService: ConfigService,
    private languageService: LanguageService,
    private scrollService: ScrollService,
    private http: HttpClient,
    private sentryLogger: SentryLogger,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.apiUrl = this.configService.cfg.corporateApiUrl;
  }

  sendContactCaseToSalesForce(payload: CorporateContactUs, userProfile: Profile): Observable<HttpResponse<Response>> {
    const url = userProfile?.corporate?.clientId
      ? `${this.apiUrl}/corporates/${userProfile.corporate.clientId}/contactUs`
      : `${this.apiUrl}/contactUs`;

    return this.getExtraHeaders(userProfile).pipe(
      switchMap((extraHeaders) => {
        return this.http
          .post<HttpResponse<Response>>(url, payload, this.getCorporateApiHeaders('application/json', extraHeaders))
          .pipe(
            catchError((err: unknown) => {
              this.sentryLogger.warn('Failed to send contact case to corporate api', { error: err });
              return throwError(() => err);
            })
          );
      })
    );
  }

  postContactRequest(payload: CorporateContactUs): Observable<HttpResponse<Response>> {
    return this.http
      .post<HttpResponse<Response>>(`${this.apiUrl}/contactrequests`, payload, this.getCorporateApiHeaders())
      .pipe(
        catchError((err: unknown) => {
          this.sentryLogger.warn('Failed to post contact request to corporate api', { error: err });
          return throwError(() => err);
        })
      );
  }

  postFile(file: File): Observable<AttachmentResponse> {
    return fileToBase64(file).pipe(
      switchMap((base64File) => {
        return this.http
          .post<AttachmentResponse>(
            `${this.apiUrl}/attachment`,
            {
              fileName: file.name,
              fileData: base64File,
            },
            this.getCorporateApiHeaders()
          )
          .pipe(
            catchError((err: unknown) => {
              this.sentryLogger.warn('Failed to post file to corporate api', { error: err });
              return throwError(() => err);
            })
          );
      })
    );
  }

  enrollNewCorporate(payload: EnrollCorporate): Observable<HttpResponse<Response>> {
    return this.http
      .post<HttpResponse<Response>>(`${this.apiUrl}/corporates`, payload, this.getCorporateApiHeaders())
      .pipe(
        catchError((err: unknown) => {
          this.sentryLogger.warn('Failed to post new corporate to corporate api', { error: err });
          return throwError(() => err);
        })
      );
  }

  getCompanyDetails(): Observable<Company> {
    return combineLatest([this.store$.pipe(profile()), this.store$.pipe(loginToken())]).pipe(
      switchMap(([userProfile, token]: [Profile, string]) => {
        return this.http.get<Company>(
          `${this.apiUrl}/corporates/${userProfile.corporate?.clientId}`,
          this.getCorporateApiHeaders('application/json', { oauth_token: token })
        );
      })
    );
  }

  updateCompanyDetails(payload: CompanyUpdate): Observable<HttpResponse<void>> {
    return combineLatest([this.store$.pipe(profile()), this.store$.pipe(loginToken())]).pipe(
      switchMap(([userProfile, token]: [Profile, string]) => {
        return this.http.patch<HttpResponse<void>>(
          `${this.apiUrl}/corporates/${userProfile.corporate?.clientId}`,
          payload,
          this.getCorporateApiHeaders('application/json', { oauth_token: token })
        );
      })
    );
  }

  getUsers(): Observable<CorporateUser[]> {
    return combineLatest([this.store$.pipe(profile()), this.store$.pipe(loginToken())]).pipe(
      switchMap(([userProfile, token]: [Profile, string]) => {
        return this.http.get<CorporateUser[]>(
          `${this.apiUrl}/corporates/${userProfile.corporate?.clientId}/users`,
          this.getCorporateApiHeaders('application/json', { oauth_token: token })
        );
      })
    );
  }

  getUser(id: string): Observable<CorporateUser> {
    return this.store$.pipe(loginToken()).pipe(
      switchMap((token: string) => {
        return this.http.get<CorporateUser>(
          `${this.apiUrl}/users/${id}`,
          this.getCorporateApiHeaders('application/json', { oauth_token: token })
        );
      })
    );
  }

  addUser(newUser: CorporateSalesForceUser): Observable<void> {
    return combineLatest([this.store$.pipe(profile()), this.store$.pipe(loginToken())]).pipe(
      switchMap(([userProfile, token]: [Profile, string]) => {
        return this.http.post<void>(
          `${this.apiUrl}/corporates/${userProfile.corporate?.clientId}/users`,
          newUser,
          this.getCorporateApiHeaders('application/json', { oauth_token: token })
        );
      })
    );
  }

  updateUser(id: string, user: CorporateSalesForceUser): Observable<void> {
    return this.store$.pipe(loginToken()).pipe(
      switchMap((token: string) => {
        return this.http.patch<void>(
          `${this.apiUrl}/users/${id}`,
          user,
          this.getCorporateApiHeaders('application/json', { oauth_token: token })
        );
      })
    );
  }

  deactivateUser(userDetails: { id: string; lastname: string }): Observable<void> {
    return this.store$.pipe(loginToken()).pipe(
      switchMap((token: string) => {
        return this.http.delete<void>(
          `${this.apiUrl}/users/${userDetails.id}?lastName=${userDetails.lastname}`,
          this.getCorporateApiHeaders('application/json', { oauth_token: token })
        );
      })
    );
  }

  getTrips(params: TripsRequest): Observable<TripsResponse> {
    return combineLatest([this.store$.pipe(profile()), this.store$.pipe(loginToken())]).pipe(
      switchMap(([userProfile, oauthToken]: [Profile, string]) => {
        return this.http
          .post<TripsResponse>(
            `${this.apiUrl}/corporates/${userProfile.corporate.clientId}/trips`,
            params,
            this.getCorporateApiHeaders('application/json', { oauth_token: oauthToken })
          )
          .pipe(
            catchError((err: unknown) => {
              this.sentryLogger.warn('Failed to get trips from corporate api', { error: err });
              return throwError(() => err);
            })
          );
      })
    );
  }

  getSpend(spendRequest: DataRequest): Observable<Data> {
    return combineLatest([this.store$.pipe(profile()), this.store$.pipe(loginToken())]).pipe(
      switchMap(([userProfile, oauthToken]: [Profile, string]) => {
        return this.http
          .post<DataResponse>(
            `${this.apiUrl}/corporates/${userProfile.corporate.clientId}/reports`,
            spendRequest,
            this.getCorporateApiHeaders('application/json', { oauth_token: oauthToken })
          )
          .pipe(
            map((response: DataResponse) => ({ clientId: userProfile.corporate.clientId, response })),
            catchError((err: unknown) => {
              this.sentryLogger.warn('Failed to get spend from corporate api', { error: err });
              return of({ clientId: userProfile.corporate.clientId, error: err } as Data);
            })
          );
      })
    );
  }

  getVatReceipt(search: VatReceiptSearch): Observable<VatReceiptResult> {
    const params = this.getVatSearchParams(search);
    const url = `${this.apiUrl}/corporates/999999/receipts?${params}`;
    return this.http.get<VatReceiptResult>(url, this.getCorporateApiHeaders()).pipe(
      retryWithBackoff(2),
      catchError((err: unknown) => {
        this.sentryLogger.warn('Failed to get VAT receipt', { error: err });
        return throwError(() => err);
      })
    );
  }

  getReceiptUrl(search: VatReceiptSearch, contactDetails: VatReceiptContactDetails): Observable<string> {
    const params = this.getVatSearchParams(search, 'PDF');

    const url = `${this.apiUrl}/corporates/999999/receipts?${params}`;
    return this.http.post<{ receiptId: string }>(url, contactDetails, this.getCorporateApiHeaders()).pipe(
      map((res) => `${this.apiUrl}/receipts/${res.receiptId}`),
      retryWithBackoff(2),
      catchError((err: unknown) => {
        this.sentryLogger.warn('Failed to download the VAT receipt from corporate api', { error: err });
        return throwError(() => err);
      })
    );
  }

  getVatSearchParams(search: VatReceiptSearch, format = 'JSON'): URLSearchParams {
    const { lastName, pnr, ticketNumber } = search;
    const params = new URLSearchParams();
    params.append('format', format);
    params.append('lastName', lastName.toUpperCase());
    if (pnr) {
      params.append('pnr', pnr.toUpperCase());
    } else {
      params.append('ticketNumber', ticketNumber.replace(/\s/g, '').slice(-10));
    }
    return params;
  }

  /*
  This will return the parent page link, so last segment of current url will be removed.
  E.g. /fi-en/finnair-business-travel/join-corporate-form -> /fi-en/finnair-business-travel
   */
  getReturnLink(): Observable<string> {
    return this.activatedRoute.url.pipe(
      map((segments: UrlSegment[]) => {
        const paths = segments
          ?.slice(0, -1)
          .map((p) => p.path)
          .filter(Boolean)
          .join('/');
        return `${this.languageService.langValue}/${paths}`;
      })
    );
  }

  scrollToInvalidField(form: FormGroup | FormGroup[], elementRef: ElementRef): void {
    Array.isArray(form) ? form.forEach((f) => f.markAllAsTouched()) : form.markAllAsTouched();
    setTimeout(() => {
      this.scrollService.scrollToFirstInvalidInput(elementRef, 40);
    });
  }

  getCorporateUserForm(user?: CorporateUser, phoneNumber?: [string, string]): FormGroup<CorporateUserForm> {
    return this.fb.group({
      personalDetails: this.fb.group({
        salutation: [user?.title ?? '', Validators.required],
        firstName: [user?.firstname ?? '', [Validators.required, Validators.pattern(NAME_PATTERN)]],
        lastName: [user?.lastname ?? '', [Validators.required, Validators.pattern(NAME_PATTERN)]],
        email: [user?.email ?? '', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
        phonePrefix: [phoneNumber?.[0] ?? '', Validators.required],
        phoneNumber: [phoneNumber?.[1] ?? '', [Validators.required, Validators.pattern(PHONE_PATTERN)]],
        dateOfBirth: [user?.birthdate ? new LocalDate(user.birthdate) : undefined],
        workTitle: [user?.jobTitle ?? ''],
        fPlusNumber: [user?.finnairPlus ?? '', finnairPlusNumberValidator()],
      }),
      passportDetails: this.fb.group({
        passportNumber: [user?.passportNumber ?? ''],
        issueDate: [user?.issued ? new LocalDate(user.issued) : undefined],
        expirationDate: [user?.expires ? new LocalDate(user.expires) : undefined],
        nationality: [user?.nationality ?? ''],
      }),
      userDetails: this.fb.group({
        role: [user?.role ?? CorporateUserRole.TRAVELLER, Validators.required],
      }),
    });
  }

  getCountryInputOptions(): Observable<SelectOption[]> {
    return this.languageService.translate('countries').pipe(
      map((countries) =>
        Object.keys(countries)
          .map(
            (country) =>
              ({
                value: country.toUpperCase(),
                name: countries[country],
              }) as SelectOption
          )
          .sort((locationA, locationB) => {
            return locationA.name > locationB.name ? 1 : -1;
          })
      )
    );
  }

  getCountryListForTerms(): { [key: string]: string } {
    return {
      AT: 'AT_EN.pdf',
      BE: 'BE_EN.pdf',
      CN: 'CN_EN.pdf',
      CZ: 'CZ_EN.pdf',
      DK: 'DK_EN.pdf',
      EE: 'EE_EN.pdf',
      FI: 'FI_EN.pdf',
      FR: 'FR_EN.pdf',
      DE: 'DE_EN.pdf',
      HK: 'HK_EN.pdf',
      HU: 'HU_EN.pdf',
      IN: 'IN_EN.pdf',
      IE: 'IE_EN.pdf',
      IT: 'IT_EN.pdf',
      KR: 'KR_EN.pdf',
      LV: 'LV_EN.pdf',
      LT: 'LT_EN.pdf',
      NL: 'NL_EN.pdf',
      NO: 'NO_EN.pdf',
      PL: 'PL_EN.pdf',
      SG: 'SG_EN.pdf',
      ES: 'ES_EN.pdf',
      SE: 'SE_EN.pdf',
      CH: 'CH_EN.pdf',
      TH: 'TH_EN.pdf',
      GB: 'UK_EN.pdf',
      US: 'US_EN.pdf',
    };
  }

  private getExtraHeaders(userProfile: Profile | undefined): Observable<{ [key: string]: string }> {
    if (userProfile) {
      return this.store$.pipe(
        loginToken(),
        map((token) => ({ oauth_token: token }))
      );
    }
    return of({});
  }

  private getCorporateApiHeaders(
    contentType = 'application/json',
    extraHeaders?: { [key: string]: string }
  ): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'X-Api-Key': this.configService.cfg.corporateApiKey,
        'Content-Type': contentType,
        ...(extraHeaders || {}),
      }),
    };
  }
}
