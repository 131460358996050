@if (content(); as content) {
  <section class="emergency-in-operations">
    <div class="col-1-12 md-col-2-9 flex flex--end pr-large-t dn-mobile">
      <fcom-button
        [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
        [text]="emergencyInOperationsTexts.homepageLinkText"
        [href]="homeLink$ | async"
        [icon]="{
          category: IconLibrary.SVG_LIBRARY,
          name: SvgLibraryIcon.ARROW_RIGHT,
          position: IconPosition.RIGHT,
        }"
      >
      </fcom-button>
    </div>

    <div class="col-1-12 md-col-2-9 flex flex--column flex-row-24">
      <cms-heading-tag [level]="1" extraClasses="nordic-blue-900-text">{{ content.teaserTitle }}</cms-heading-tag>

      <div class="col-1-12 md-col-2-9 flex flex--end pr-large-t dn-tablet-up">
        <fcom-button
          [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
          [text]="emergencyInOperationsTexts.homepageLinkText"
          [href]="homeLink$ | async"
          [icon]="{
            category: IconLibrary.SVG_LIBRARY,
            name: SvgLibraryIcon.ARROW_RIGHT,
            position: IconPosition.RIGHT,
          }"
        >
        </fcom-button>
      </div>

      <span class="bold-type" [innerHTML]="content.teaserText | finSafe: 'html'"></span>

      <div class="date-socials flex flex--space-between flex--middle">
        <div class="left">
          <fin-date
            class="block"
            [text]="publishedText"
            [date]="content.localSettings?.firstPublished ? content.localSettings.firstPublished : content.creationDate"
          ></fin-date>

          @if (content.subjectTaxonomyTags?.includes(CmsTaxonomyGeneral.EMERGENCY_IN_OPERATIONS_UPDATED)) {
            <fin-date
              class="block"
              [text]="updatedText"
              [date]="
                content.externallyDisplayedDate !== content.modificationDate
                  ? content.externallyDisplayedDate
                  : content.modificationDate
              "
            ></fin-date>
          }
        </div>
        <div class="right">
          <fin-social [url]="content.url" [title]="content.teaserTitle" [colour]="'nordic-blue-900-bg'"></fin-social>
        </div>
      </div>

      <span [innerHTML]="content.detailText | finSafe: 'html'"></span>

      <div class="further-info flex flex--wrap">
        <p class="flex--basis-full" [innerHTML]="emergencyInOperationsTexts.furtherInfo"></p>
        <fcom-notification
          class="info-block"
          [theme]="NotificationTheme.TRANSPARENT"
          [layout]="NotificationLayout.BLOCK"
          [buttonStyle]="NotificationButtonStyle.LINK"
          [iconName]="SvgLibraryIcon.PHONE_BLOCK"
          [title]="emergencyInOperationsTexts.callFamiliesTitle"
        >
          <a class="bold-type nordic-blue-900-text" href="{{ emergencyInOperationsTexts.callFamiliesNumber }}">{{
            emergencyInOperationsTexts.callFamiliesNumberText
          }}</a>

          @if (emergencyInOperationsTexts.callFamiliesLocalSurcharge) {
            <br />{{ emergencyInOperationsTexts.callFamiliesLocalSurcharge }}
          }
        </fcom-notification>

        <fcom-notification
          class="info-block"
          [theme]="NotificationTheme.TRANSPARENT"
          [layout]="NotificationLayout.BLOCK"
          [buttonStyle]="NotificationButtonStyle.LINK"
          [iconName]="SvgLibraryIcon.PHONE_BLOCK"
          [title]="emergencyInOperationsTexts.callMediaTitle"
        >
          <a class="bold-type nordic-blue-900-text" href="{{ emergencyInOperationsTexts.callMediaNumber }}">{{
            emergencyInOperationsTexts.callMediaNumberText
          }}</a>

          @if (emergencyInOperationsTexts.callMediaLocalSurcharge) {
            <br />{{ emergencyInOperationsTexts.callMediaLocalSurcharge }}
          }
        </fcom-notification>

        @if (!content.localSettings.hideChatInfo) {
          <fcom-notification
            class="info-block"
            [theme]="NotificationTheme.TRANSPARENT"
            [layout]="NotificationLayout.BLOCK"
            [buttonStyle]="NotificationButtonStyle.LINK"
            [iconName]="SvgLibraryIcon.CHAT_BLOCK"
            [title]="emergencyInOperationsTexts.genContactTitle"
          >
            <button data-testid="openChat" class="bold-type underline chat-button" (click)="initChat()">
              {{ emergencyInOperationsTexts.chatLinkText }}
            </button>

            @if (emergencyInOperationsTexts.chatAfterText) {
              <br />{{ emergencyInOperationsTexts.chatAfterText }}
            }
          </fcom-notification>
        }
      </div>

      @for (item of content.related; track item.contentId; let last = $last) {
        <fin-cms-json-content [template$]="{ main: [item] } | finToObservable"></fin-cms-json-content>
      } @empty {}
    </div>
  </section>
}
