import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { CustomIcon, IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable, map } from 'rxjs';

import { BreadcrumbItem, ElementTypes, GaContext } from '@fcom/common/interfaces';
import { CmsTemplate } from '@fcom/core-api';
import { LanguageService } from '@fcom/ui-translate';
import { NavigationMenuService } from '@fcom/common';
import { LanguageSelectorContext } from '@fcom/common/components/language-selector/language-selector.component';

@Component({
  selector: 'cms-emergency-in-operations',
  templateUrl: './cms-emergency-in-operations.component.html',
  styleUrl: './cms-emergency-in-operations.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsEmergencyInOperationsComponent implements OnInit {
  readonly currentLanguage$: Observable<string>;
  readonly CustomIcon = CustomIcon;
  readonly ElementTypes = ElementTypes;
  readonly GaContext = GaContext;
  readonly IconLibrary = IconLibrary;
  readonly LANGUAGE_SELECTOR_CONTEXT = LanguageSelectorContext.TOP_NAVI;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  homeLink$: Observable<string>;
  oneworldLink$: Observable<BreadcrumbItem>;

  @Input() template$: Observable<CmsTemplate>;

  constructor(
    private navigationMenuService: NavigationMenuService,
    private languageService: LanguageService
  ) {
    this.currentLanguage$ = this.languageService.langKey;
  }

  ngOnInit(): void {
    this.homeLink$ = this.languageService.lang.pipe(map((lang): string => `/${lang}`));
    this.oneworldLink$ = this.navigationMenuService.oneworldLink$;
  }
}
