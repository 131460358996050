<div class="df-laptop-up landing-page pr-large-t">
  <div class="country-selector-wrapper">
    <div class="country-selector align-center">
      <fcom-icon
        class="finnair-logo nordic-blue-900-text mr-large-y"
        [category]="IconLibrary.CUSTOM"
        [name]="CustomIcon.FINNAIR_LOGO"
      ></fcom-icon>
      <fin-country-and-language-selector
        [useH1]="true"
        [prefill]="false"
        [showCloseButton]="false"
        (applySelection)="applySelection($event)"
      ></fin-country-and-language-selector>
      <div class="ms-large-t font-body-2">Your country wasn't listed?</div>
      <a class="nordic-blue-900-text font-body-2" href="/en">Go to the international site</a>
    </div>
  </div>
  <div
    *ngIf="imageUrl$ | async"
    class="image-wrapper dn-laptop-down"
    [ngStyle]="{ 'background-image': 'url(' + (imageUrl$ | async) + ')' }"
  ></div>
</div>
