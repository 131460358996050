import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { unsubscribe } from '@fcom/core/utils/unsubscribe';

import { ButtonMode, ButtonTheme } from './../../buttons/button/enums';
import { IconPosition } from '../../icons';
import { ResponsiveImage } from '../../images/interfaces';
import { TagTheme } from '../../tag';
import { NotificationTheme } from '../../notifications';

const stopPropagation = (event: Event): boolean => {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }
  return false;
};

@Component({
  selector: 'fcom-product-card',
  templateUrl: './product-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements AfterViewInit, OnDestroy {
  readonly TagTheme = TagTheme;
  readonly ButtonMode = ButtonMode;
  readonly IconLibrary = IconLibrary;
  readonly IconPosition = IconPosition;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input() hasAddedItems: boolean;

  /**
   * The title of the product.
   */
  @Input() title: string;

  /**
   * The detailed description of the product.
   */
  @Input() detailText: string;

  /**
   * The image of the product.
   */
  @Input() image: ResponsiveImage;

  /**
   * The current status text to be shown for the product.
   * Should be used to to show for example how many of this product we have selected
   */
  @Input() statusText: string;

  /**
   * The purchased text to be shown for the product.
   * Should be used to to show how many of this product have previously been bought
   */
  @Input() purchasedText: string;

  /**
   * Price of the product
   */
  @Input() price: string;

  /**
   * Text to be shown for view button
   */
  @Input() viewSummaryText: string;

  /**
   * Primary button loading state for the product
   */
  @Input() loading: boolean;

  /**
   * Primary button theme
   */
  @Input() buttonTheme: ButtonTheme;

  /**
   * Primary button text
   */
  @Input() buttonText: string;

  /**
   * Primary button aria text
   */
  @Input() buttonAriaText: string;

  /**
   * If button should be disabled for the product
   */
  @Input() hideButton = false;

  /**
   * Hides the view button of the product card if set
   */
  @Input() hideViewButton = false;

  /**
   * Primary button icon
   */
  @Input() buttonIcon: SvgLibraryIcon = SvgLibraryIcon.PLUS;

  /**
   * Notification translated text
   */
  @Input() notificationText?: string;

  /**
   * Notification theme
   */
  @Input() notificationTheme: NotificationTheme = NotificationTheme.INFO;

  @Input() focusOnViewSummary$: Observable<boolean>;
  @Input() focusOnBtn$: Observable<boolean>;

  @Input() badgeText?: string;

  @Input() lowestPrice?: string = undefined;

  @Input() productTagText?: string = undefined;

  @Input() productTagIcon?: SvgLibraryIcon = undefined;

  /**
   * Event emitter for selectProduct event from primary button
   */
  @Output() selectProduct: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Event emitter for viewDetails event from view button
   */
  @Output() viewDetails: EventEmitter<void> = new EventEmitter<void>();

  private subscriptions: Subscription = new Subscription();

  @ViewChild('viewSummaryBtn', { static: false }) viewSummaryBtn: ElementRef;

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.focusOnViewSummary$
        .pipe(filter((focus) => !this.hideButton && focus))
        .subscribe(() => this.viewSummaryBtn.nativeElement.focus())
    );
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  emitProductCategory(event: Event): void {
    stopPropagation(event);
    this.selectProduct.emit();
  }

  emitViewDetails(event: Event): void {
    stopPropagation(event);
    this.viewDetails.emit();
  }
}
