@if (content(); as content) {
  <div class="cmrelated-list grid-col-12 no-gutters">
    @if (content.teaserTitle) {
      <cms-heading-tag
        data-testid="heading-tag"
        [class]="content.gridClass || 'col-1-12 md-col-1-12'"
        [level]="headerLevel(content.subjectTaxonomyTags || []) ?? DEFAULT_HEADER_LEVEL"
        [extraClasses]="headerFontLevel(content.subjectTaxonomyTags || []) ?? 'font-heading-3'"
        >{{ content.teaserTitle }}</cms-heading-tag
      >
    }
    @for (item of content.items; track item.contentId) {
      <fin-teaser-card
        class="col-1-12 card-wrapper grid-third"
        [aspectRatio]="'16x9'"
        [content]="item"
      ></fin-teaser-card>
    }
    @if (content.teaserText) {
      <div class="col-1-12 md-col-1-12 teaser-text flex" [innerHTML]="content.teaserText | finSafe: 'html'"></div>
    }
  </div>
}
