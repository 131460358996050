import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { PageIndexes } from '../pagination/pagination.component';
import { LoaderTheme } from '../loader';

export type ClassOptions =
  | string
  | string[]
  | Set<string>
  | {
      [key: string]: any;
    }
  | null
  | undefined;

export interface RowDataItem<T> {
  [key: string]: {
    data: T;
    options?: {
      class?: ClassOptions;
      useBtn?: boolean;
      btnIcon?: SvgLibraryIcon;
      btnText?: string;
    };
  };
}

export interface HeaderItem {
  key: string;
  value?: string;
  options?: {
    class?: ClassOptions;
    isMarked?: boolean;
  };
}

/**
 * Table component which works with different templates
 *
 * @example
 *  <fin-table
 *    [rowData]="rowData"
 *    [cellTemplate]="itemTemplate"
 *    [headers]="header"
 *    [headerTemplate]="headerTemplate"
 *   ></fin-table>
 */
@Component({
  selector: 'fcom-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent<T> implements OnChanges {
  protected readonly LoaderTheme = LoaderTheme;

  @Input()
  headers: HeaderItem[];

  @Input()
  rowData: RowDataItem<T>[];

  @Input()
  cellTemplate: TemplateRef<any>;

  @Input()
  mobileHeader: TemplateRef<any>;

  @Input()
  mobileTemplate: TemplateRef<any>;

  @Input()
  usePagination = false;

  @Input()
  textBeforePages?: string;

  @Input()
  itemsPerPageOptions?: number[] = [8, 16];

  @Input()
  headerTemplate?: TemplateRef<any>;

  @Input()
  caption: string;

  @Input()
  loading: boolean;

  @Input()
  noResultsMsg: string;

  @Output()
  clickBtn = new EventEmitter<T>();

  tableData: RowDataItem<T>[];

  itemsPerPage = this.itemsPerPageOptions[0];
  totalPaginationItems = 0;

  @ViewChildren('tableHeader', { read: ElementRef }) tableHeaders: QueryList<ElementRef>;

  ngOnChanges(): void {
    this.totalPaginationItems = this.rowData?.length ?? 0;
    if (!this.usePagination) {
      this.tableData = this.rowData;
    } else {
      this.tableData = this.rowData?.slice(0, this.itemsPerPageOptions[0]);
    }
  }

  changeIndexes({ startIndex, endIndex }: PageIndexes): void {
    this.tableData = this.rowData?.slice(startIndex, endIndex);
  }

  emitClick(data: T): void {
    this.clickBtn.emit(data);
  }
}
