<div
  *ngIf="links$ | async as links"
  class="flex flex--space-between white-bg"
  [ngClass]="{
    'border rounded-large ps-large': showBorder,
  }"
>
  <a
    *ngFor="let link of links"
    class="flex flex--column flex--center align-center no-underline font-body-1 bold-type nordic-blue-900-text"
    [finLink]="link.url"
    [external]="link.external"
    (click)="clickLink.emit(link)"
  >
    <fcom-icon
      *ngIf="link.iconName"
      class="ms-small-b"
      [ngClass]="{
        'ms-xsmall-b': (isMobile$ | async) === false || showCompact,
        'ms-small-b': (isMobile$ | async) === true || !showCompact,
        'icon-xlarge': (isMobile$ | async) === false && !showCompact,
        'icon-large': (isMobile$ | async) === true || showCompact,
      }"
      [name]="link.iconName"
    ></fcom-icon>
    {{ link.title }}
  </a>
</div>
