import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { CustomIcon, IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { CountryState, unsubscribe, RootPaths } from '@fcom/core';
import { isVisible, safeMap, stopPropagation } from '@fcom/core/utils';
import { finShare, keepState } from '@fcom/rx';
import { allowedConfs } from '@fcom/core/reducers/langs';
import { ButtonTheme, IconButtonSize, IconButtonTheme, IconPosition } from '@fcom/ui-components';

import { MenuItem, MenuJson, ElementTypes, GaContext, ElementActions } from '../../../interfaces';
import { NavigationMenuService, PageMetaService } from '../../../services';
import { CountryAndLanguageSelection } from '../../country-and-language-selector/country-and-language-selector.component';
import { getLocalizedLinkForLangCode } from '../../../utils/language.utils';
import { GtmService } from '../../../gtm/services/gtm.service';
import { isNavigationEvent } from '../../../utils';

@Component({
  selector: 'fin-navigation',
  styleUrls: ['./navigation.component.scss'],
  templateUrl: './navigation.component.html',
})
export class NavigationComponent implements OnDestroy {
  static readonly TRANSITION_DURATION_MS = 300;
  static readonly START_MENU_ITEM_APPEAR_AFTER_MS = 100;

  readonly ButtonTheme = ButtonTheme;
  readonly CustomIcon = CustomIcon;
  readonly ElementTypes = ElementTypes;
  readonly IconButtonSize = IconButtonSize;
  readonly IconButtonTheme = IconButtonTheme;
  readonly IconLibrary = IconLibrary;
  readonly IconPosition = IconPosition;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  readonly isMenuOpen$: Observable<boolean>;
  readonly menuInDom$: Observable<boolean>;
  readonly homeLink$: Observable<string>;
  readonly menuItems$: Observable<MenuJson>;
  readonly countriesList$: Observable<CountryState[]>;
  readonly currentLanguage$: Observable<string>;
  readonly appearAfter = NavigationComponent.START_MENU_ITEM_APPEAR_AFTER_MS;
  readonly expandedNaviCat$: Observable<MenuItem>;
  readonly _selectLanguageRoot: string = '/';

  countries$: Observable<CountryState>;
  enableNewLanguageSelector$: Observable<boolean>;
  languageSelectorOpen: boolean;

  private subscriptions = new Subscription();
  private removeFocusListener: Function;

  constructor(
    private navigationMenuService: NavigationMenuService,
    private languageService: LanguageService,
    private elementRef: ElementRef,
    private ngZone: NgZone,
    private router: Router,
    private pageMetaService: PageMetaService,
    private gtmService: GtmService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.isMenuOpen$ = this.navigationMenuService.isNavigationMenuOpen$;
    this.menuItems$ = this.navigationMenuService.menuItems$;
    this.homeLink$ = this.languageService.lang.pipe(map((lang) => `/${lang}`));
    this.currentLanguage$ = this.languageService.langKey;
    this.countries$ = this.initListOfCountries();
    this.expandedNaviCat$ = navigationMenuService.expandedNaviCategory;

    this.menuInDom$ = this.isMenuOpen$.pipe(
      keepState(NavigationComponent.TRANSITION_DURATION_MS),
      distinctUntilChanged()
    );

    this.subscriptions.add(
      this.isMenuOpen$.subscribe((isOpen) => {
        if (isOpen) {
          this.bindEventListeners();
        } else {
          this.cleanUpEventHandlers();
        }
      })
    );

    this.enableNewLanguageSelector$ = this.router.events.pipe(
      filter(isNavigationEvent),
      map(({ url }) => url),
      startWith(this.router.url),
      map((url) => !this.isRootPath(url)),
      finShare()
    );
  }

  setCategoryAsExpanded(event: Event, item: MenuItem) {
    this.navigationMenuService.setCategoryAsExpanded(event, item);
    this.elementRef.nativeElement.querySelector('.navigation-wrapper').scrollTop = 0;
  }

  closeNaviMenu(): void {
    this.navigationMenuService.closeNaviMenu();
  }

  closeMenu(): void {
    this.navigationMenuService.closeMenu();
  }

  trackItems(index: number) {
    return index;
  }

  openLanguageSelector(): void {
    this.gtmService.trackElement(
      'language-selector-open',
      GaContext.TOP_NAVIGATION,
      ElementTypes.BUTTON,
      undefined,
      ElementActions.OPEN
    );

    this.languageSelectorOpen = true;
  }

  closeLanguageSelector(): void {
    this.languageSelectorOpen = false;
  }

  selectLanguageAndRedirect(selection: CountryAndLanguageSelection): void {
    this.gtmService.trackElement(
      'language-selector-select',
      GaContext.TOP_NAVIGATION,
      ElementTypes.BUTTON,
      selection.languageCode,
      ElementActions.SUBMIT
    );

    this.subscriptions.add(
      this.pageMetaService.links.subscribe((links) => {
        const url = getLocalizedLinkForLangCode(selection.languageCode, links, undefined);
        this.router.navigateByUrl(url);
      })
    );

    this.closeLanguageSelector();
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  private initListOfCountries(): Observable<CountryState> {
    return this.languageService.countryCode.pipe(
      map(
        (countryCode) =>
          allowedConfs.find((country) => country.code === countryCode) || {
            languages: [],
            name: '',
            code: '',
          }
      )
    );
  }

  // @ToDo: create utility to handle focus trapping in a centralized way
  private bindEventListeners(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.ngZone.runOutsideAngular(() => {
      const listener = (event) => {
        return self.ngZone.run(() => {
          self.onNaviFocus(event);
        });
      };
      self.document.addEventListener('focus', listener, true);
      self.document.addEventListener('focusin', listener);
      self.removeFocusListener = () => {
        self.document.removeEventListener('focus', listener, true);
        self.document.removeEventListener('focusin', listener);
      };
    });
  }

  private onNaviFocus(event: FocusEvent): void {
    const el = safeMap(this.elementRef, (e) => e.nativeElement);
    if (!!el && !el.contains(event.target)) {
      stopPropagation(event);

      const focusSelector = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
      const focusableElements: Element[] = Array.from((el as Element).querySelectorAll(focusSelector))
        .filter((e) => e instanceof HTMLElement)
        .filter(isVisible);

      if (focusableElements.length !== 0) {
        (focusableElements[0] as HTMLElement).focus();
      }
    }
  }

  private cleanUpEventHandlers() {
    if (this.removeFocusListener) {
      this.removeFocusListener();
      this.removeFocusListener = undefined;
    }
  }

  private isRootPath(path: string): boolean {
    return Object.values(RootPaths).some((rootPath) => path.includes(rootPath));
  }
}
