import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { share } from 'rxjs/operators';

import { SentryLogger } from '@fcom/core';
import { ConfigService } from '@fcom/core/services/config/config.service';
import { retryWithBackoff, snapshot } from '@fcom/rx';
import { unsubscribe } from '@fcom/core/utils';
import { FinnairRequestItineraryItem, FinnairSearchParameters, OfferList } from '@fcom/dapi/api/models';
import { OffersService } from '@fcom/dapi/api/services';
import { OfferListFetchParams } from '@fcom/common/interfaces';
import { AirOffersStatus, BookingAppState } from '@fcom/common/interfaces/booking';

import { OffersActions } from '../../../store/actions';
import { offerSerializedLastRequestParams, offersStatus } from '../../../store/selectors';

@Injectable()
export class BookingOfferListService implements OnDestroy {
  static NUMBER_OF_RETRIES = 3;

  private subscription: Subscription;

  constructor(
    private configService: ConfigService,
    private store$: Store<BookingAppState>,
    private sentryLogger: SentryLogger,
    private offersService: OffersService
  ) {}

  ngOnDestroy(): void {
    this.subscription = unsubscribe(this.subscription);
  }

  /**
   * Triggers offer fetching for the given parameters. Stores the result
   * in the state tree under {@link AirOffersState}
   */
  triggerFetchOffers(params: OfferListFetchParams): void {
    if (this.subscription) {
      this.subscription = unsubscribe(this.subscription);
    }
    const serialized: string = snapshot(this.store$.pipe(offerSerializedLastRequestParams()));
    const offerStatus: AirOffersStatus = snapshot(this.store$.pipe(offersStatus()));
    const currentParamsSerialized = JSON.stringify(params);

    // TODO: we need to fetch offers again when they expires, even if the params stay the same
    if (offerStatus === AirOffersStatus.OK && serialized === currentParamsSerialized) {
      return;
    }

    this.store$.dispatch(OffersActions.startLoading());
    this.store$.dispatch(OffersActions.offerSetLastRequestParams({ params }));
    this.subscription = this.getOffersForLocation(params)
      .pipe(retryWithBackoff(BookingOfferListService.NUMBER_OF_RETRIES))
      .subscribe({
        next: (data: OfferList) => {
          if (['NO_FLIGHTS_FOUND', 'NO_FLIGHTS_LEFT_FOR_TODAY'].indexOf(data.status) !== -1) {
            this.store$.dispatch(OffersActions.noFlightsFound());
          } else {
            this.store$.dispatch(OffersActions.setOffers({ offers: data }));
          }
          this.subscription = unsubscribe(this.subscription);
        },
        // eslint-disable-next-line rxjs/no-implicit-any-catch
        error: (error: HttpErrorResponse) => {
          const status: string = error?.error?.status;

          if (status === 'INVALID_INPUT') {
            this.store$.dispatch(OffersActions.invalidInput());
          } else {
            this.sentryLogger.error('Error fetching offers', { error });
            this.store$.dispatch(OffersActions.error());
          }
          this.subscription = unsubscribe(this.subscription);
        },
        complete: () => {
          this.subscription = unsubscribe(this.subscription);
        },
      });
  }

  /**
   * Get flight offers for a certain day.
   *
   * @param locale the locale to send with the request
   * @param cabin the travel class to query
   * @param paxAmount passenger amounts
   * @param flights an array of origin, destination, departureDate
   * @param directFlights whether to search for only direct flights or also with connections
   * @param isAward should we fetch award flow offers
   * @returns {Observable<OfferList>} the fetched offers
   */
  getOffersForLocation({
    locale,
    cabin,
    paxAmount,
    directFlights,
    isAward,
    flights,
  }: OfferListFetchParams): Observable<OfferList> {
    const itinerary: FinnairRequestItineraryItem[] = flights.map((f, index) => ({
      departureLocationCode: f.origin,
      destinationLocationCode: f.destination,
      departureDate: f.departureDate.toString(),
      isRequestedBound: index === 0,
    }));

    const params: FinnairSearchParameters = {
      itinerary,
      adults: paxAmount.adults,
      children: paxAmount.children,
      c15s: paxAmount.c15s,
      infants: paxAmount.infants,
      cabin,
      directFlights,
      locale,
      ...(isAward && { isAward }),
    };

    return this.offersService.findOfferList1(this.configService.cfg.offersUrl, { body: params }).pipe(share());
  }
}
