import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Subscription } from 'rxjs';

import { SelectOption } from '@fcom/common';
import { unsubscribe } from '@fcom/core/utils/unsubscribe';

export interface PageIndexes {
  startIndex: number;
  endIndex: number;
}

@Component({
  selector: 'fcom-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  totalItems: number;

  @Input()
  textBeforePages: string;

  @Input()
  itemsPerPageOptions?: number[] = [4, 8, 16];

  @Input({ required: true })
  get itemsPerPage(): number {
    return this._itemsPerPage;
  }
  set itemsPerPage(value: number) {
    this._itemsPerPage = value;
    this.itemsPerPageChange.emit(this._itemsPerPage);
  }

  @Output()
  itemsPerPageChange = new EventEmitter<number>();

  @Output()
  indexesChange = new EventEmitter<PageIndexes>();

  options: SelectOption[] = [];
  totalPages: number;
  currentPage = 1;
  startIndex: number;
  endIndex: number;
  reactiveForm: UntypedFormGroup;

  private subscriptions: Subscription = new Subscription();
  private _itemsPerPage: number;

  readonly FIRST_PAGE = 1;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.options = this.itemsPerPageOptions.map((value) => ({
      name: `${value}`,
      value: `${value}`,
    }));

    this.createForm();

    this.totalPages = Math.ceil(this.totalItems / this._itemsPerPage);
    this.setIndexes(this.FIRST_PAGE, true);
    this.subscriptions.add(
      this.reactiveForm.get('paginationCount').valueChanges.subscribe((value) => {
        this.itemsPerPage = +value;
        this.reset();
      })
    );
  }

  public reset(): void {
    this.totalPages = Math.ceil(this.totalItems / this._itemsPerPage);
    this.changePage(this.FIRST_PAGE);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.itemsPerPage && !changes.itemsPerPage.firstChange) {
      this.reactiveForm.get('paginationCount').setValue(changes.itemsPerPage.currentValue);
    }
    if (!changes.totalItems?.firstChange) {
      this.reset();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  private createForm(): void {
    this.reactiveForm = this.fb.group({
      paginationCount: [this.options[0].value],
    });
  }

  prevPagination(): void {
    this.changePage(this.currentPage - 1);
  }

  nextPagination(): void {
    this.changePage(this.currentPage + 1);
  }

  private changePage(newPageNumber: number): void {
    if (newPageNumber < this.FIRST_PAGE) {
      newPageNumber = this.FIRST_PAGE;
    } else if (newPageNumber > this.totalPages) {
      newPageNumber = this.totalPages;
    }

    this.currentPage = newPageNumber;
    this.setIndexes(newPageNumber);
  }

  private setIndexes(currentPage: number, initial = false): void {
    if (!this._itemsPerPage) {
      return;
    }
    this.startIndex = (currentPage - 1) * this._itemsPerPage;
    this.endIndex = this.startIndex + this._itemsPerPage;
    if (!initial) {
      this.indexesChange.emit({
        startIndex: this.startIndex,
        endIndex: this.endIndex,
      });
    }
  }
}
