<header
  class="emergency-in-operations--header medium-type fill border-bottom nordic-blue-900-border"
  data-elastic-exclude
>
  <div
    class="navigation-heading-top center grid-max-width padding-left-gutter padding-right-gutter flex flex--space-between flex--middle ps-xsmall-y font-body-1"
    data-testid="header-heading"
  >
    <div class="flex flex--middle">
      <a [finLink]="homeLink$ | async">
        <span class="is-vishidden">{{ 'actions.home' | finLocalization }}</span>
        <fcom-icon
          class="finnair-blue nordic-blue-900-text"
          [category]="IconLibrary.CUSTOM"
          [name]="CustomIcon.FINNAIR_LOGO"
        ></fcom-icon>
      </a>

      <a [finLink]="oneworldLink$ | finMap: 'url' | async" data-testid="oneworld-link" class="ms-large-l font-body-1">
        <span class="is-vishidden">{{ oneworldLink$ | finMap: 'title' | async }}</span>
        <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.ONEWORLD_LOGO"></fcom-icon>
      </a>
    </div>

    <div class="flex flex--middle">
      <div class="align-right">
        <fin-language-selector [context]="LANGUAGE_SELECTOR_CONTEXT"></fin-language-selector>
      </div>
    </div>
  </div>
</header>

<section class="emergency-in-operations grid-col-12 grid-max-width">
  <div class="col-1-12 md-col-2-9 flex flex--end mr-large-b">
    <fin-cms-json-content [template$]="template$"></fin-cms-json-content>
  </div>
</section>
