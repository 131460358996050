import { Segment } from '../interfaces/multivariate-test.interface';

export enum QualtricsMessageTypes {
  OPTIN_RESPONSE = 'optInResponse',
  SURVEY_DONE = 'surveyDone',
  CONFSURVEY_DONE = 'confSurveyDone',
  NPS_RATING_RESPONSE = 'npsRatingResponse',
}

export const messageAllowedForSurvey = (surveyName: string, messageType: string): boolean => {
  return messageMap[messageType].indexOf(surveyName) !== -1;
};

export const QUALTRICS_DOMAIN = 'https://finnair.eu.qualtrics.com';

export interface QualtricsSurvey {
  name: string;
  url: string;
}

interface QualtricsSurveyMap {
  FEEDBACK: QualtricsSurvey;
  CORPORATE_FEEDBACK: QualtricsSurvey;
  MMB_FEEDBACK: QualtricsSurvey;
  CHECK_IN: QualtricsSurvey;
  PURCHASE_FEEDBACK: QualtricsSurvey;
  AWARD_FEEDBACK: QualtricsSurvey;
  UPGRADE_FEEDBACK: QualtricsSurvey;
  PAGE_FEEDBACK: QualtricsSurvey;
  CONFIRMATION_MULTI_CITY: QualtricsSurvey;
  MULTI_CITY: QualtricsSurvey;
}

export const QualtricsSurveys: QualtricsSurveyMap = {
  FEEDBACK: { name: 'qualtrics-feedback', url: 'feedback.generalSurveyUrl' },
  CORPORATE_FEEDBACK: { name: 'qualtrics-corporate-feedback', url: 'feedback.corporateSurveyUrl' },
  MMB_FEEDBACK: { name: 'qualtrics-mmb-feedback', url: 'feedback.mmbSurveyUrl' },
  CHECK_IN: { name: 'qualtrics-check-in-feedback', url: 'feedback.checkInSurveyUrl' },
  PURCHASE_FEEDBACK: { name: 'qualtrics-purchase-feedback', url: 'feedback.postBookingSurveyUrl' },
  CONFIRMATION_MULTI_CITY: { name: 'qualtrics-multi-city-feedback', url: 'feedback.postBookingMultiCitySurveyUrl' },
  AWARD_FEEDBACK: { name: 'qualtrics-award-feedback', url: 'feedback.postAwardBookingSurveyUrl' },
  UPGRADE_FEEDBACK: { name: 'qualtrics-upgrade-feedback', url: 'feedback.upgradeSurveyUrl' },
  PAGE_FEEDBACK: { name: 'qualtrics-page-feedback', url: 'feedback.pageSurveyUrl' },
  MULTI_CITY: { name: 'qualtrics-multi-city', url: 'feedback.multiCitySurveyUrl' },
};

const messageMap = {
  [QualtricsMessageTypes.OPTIN_RESPONSE]: [],
  [QualtricsMessageTypes.SURVEY_DONE]: [QualtricsSurveys.FEEDBACK.name],
  [QualtricsMessageTypes.CONFSURVEY_DONE]: [QualtricsSurveys.PURCHASE_FEEDBACK.name],
  [QualtricsMessageTypes.NPS_RATING_RESPONSE]: [],
};

const getQualtricsLanguage = (languageSite: string, fallbackLanguage: string): string => {
  switch (languageSite) {
    case 'hk-zh':
      return 'zh-t';
    case 'cn-zh':
      return 'zh-s';
    case 'es-es':
      return 'es-es';
    default:
      return fallbackLanguage;
  }
};

export const getQualtricsUrl = (
  fallbackLanguage: string,
  languageSite: string,
  url: string,
  segment?: Segment,
  hotjarUserId?: string
): string => {
  if (!url) {
    return null;
  }

  let urlWithParams = `${url}?Q_Language=${getQualtricsLanguage(languageSite, fallbackLanguage)}`;

  if (hotjarUserId) {
    urlWithParams += `&Hotjar_UserId=${hotjarUserId}`;
  }

  if (segment) {
    urlWithParams += `&Test_Segment=${segment}`;
  }

  return urlWithParams;
};
