export interface Consent {
  // TODO: since we are using dynamic values for this now, we should aim to remove the enum from the models and if needed to use it only for testing purposes
  consentTextId: ConsentTextId;
  effectiveFrom: number;
  text: string;
  textHash: string;
  consentStatus: ConsentStatus;
  reConsentRequired?: boolean;
}

export interface ConsentGroup {
  fplusMember: string;
  consentGroup: string;
  introductionText: string | null;
  afterwordText: string | null;
  profileAfterwordText: string | null;
  consents: Consent[];
}

export interface ConsentTextsGroup {
  consentGroup: string;
  locale: string;
  introductionText: string | null;
  afterwordText: string | null;
  profileAfterwordText: string | null;
  consentTexts: ConsentText[];
}

export interface ConsentText {
  consentTextId: string;
  effectiveFrom: number;
  text: string;
  textHash: string;
}

export interface ConsentData {
  [key: string]: boolean;
}

export enum ConsentStatus {
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  WITHDRAWN = 'withdrawn',
}

export enum ConsentTextId {
  SMS_MESSAGING = 'fplus-marketing-sms-messaging',
  THIRD_PARTY_OFFERS = 'fplus-marketing-third-party-offers',
  MEMBER_OFFERS = 'fplus-marketing-member-offers',
  FLIGHT_OFFERS = 'fplus-marketing-flight-offers',
  COOKIE_PERSONALIZATION = 'f-cookies-personalization',
  COOKIE_ANALYTICS = 'f-cookies-analytics',
  COOKIE_ADVERTISING = 'f-cookies-advertising',
  NOT_REAL_CONSENT_VALUE = 'not-real-consentValue',
}
