import type { AbstractControl, AbstractControlOptions } from '@angular/forms';

export enum FormStatus {
  INITIAL,
  LOADING,
  COMPLETE,
  ERROR,
  INITIALIZING,
  INITIALIZING_ERROR,
  PRECONDITION_REQUIRED,
}

export enum AnalyticsFormStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  SEND_ATTEMPT = 'send-attempt',
  START = 'start',
  PRECONDITION_REQUIRED = 'precondition-required',
}

export enum ReactiveFormStatus {
  INVALID = 'INVALID',
}

export type FormConfig = Record<string, [string, AbstractControlOptions['validators']?]>;

export enum FormInputType {
  INPUT = 'input',
  TEXTAREA = 'textarea',
  SELECT = 'select',
}

export interface CheckboxOption {
  label: string;
  id: string;
}

export type CheckboxOptionList = CheckboxOption[];

export type FormValue<T extends AbstractControl> = T extends AbstractControl<infer TValue, any> ? TValue : never;
export type FormRawValue<T extends AbstractControl> =
  T extends AbstractControl<any, infer TRawValue> ? TRawValue : never;
