<ng-container *ngFor="let notification of notifications$ | async; let first = first; let last = last; let i = index">
  <fcom-notification
    *ngIf="notification?.text?.label | finLocalization: notification.text.params as notificationText"
    [theme]="notification.theme"
    [iconName]="notification.icon"
    class="block"
    [class.ps-medium-t]="first"
    [class.pr-xxsmall-b]="!last"
    [attr.data-testid]="'booking-widget-notification-' + notification.type"
  >
    <ng-container *ngIf="!isAm">
      <p [innerHTML]="notificationText | resolveLinks | finSafe: 'html'"></p>
      <p
        *ngIf="notification?.text1?.label | finLocalization as notificationSecondaryText"
        [innerHTML]="notificationSecondaryText | resolveLinks | finSafe: 'html'"
      ></p>
      <a
        *ngIf="notification?.link"
        [finLink]="
          notification.link.url.startsWith('/')
            ? (notification.link.url | resolveLinks)
            : (notification.link.url | finLocalization | resolveLinks)
        "
        [external]="!!notification.link?.external"
        [openInNewTab]="!!notification.link?.openInNewTab"
        [attr.rel]="'noreferrer'"
        class="underline medium-type link-hover nordic-blue-900-text font-body-1 mr-xsmall-t block"
        data-testid="booking-widget-notification-link"
      >
        <span class="ms-xxsmall-r">{{ notification.link?.text | finLocalization }}</span>
        <fcom-icon
          *ngIf="notification.link?.external"
          class="external-link icon-medium"
          [name]="SvgLibraryIcon.EXTERNAL_TAB"
        ></fcom-icon>
      </a>
    </ng-container>
    <fin-expand *ngIf="isAm" #expand [key]="'notification-' + i" [id]="'notification-' + i" [initiallyOpen]="false">
      <div class="trigger flex flex--center">
        <div class="flex flex-1 flex--middle mr-xsmall-r">
          <span class="font-body-1" [innerHTML]="notificationText | resolveLinks | finSafe: 'html'"></span>
        </div>
        <fcom-icon
          class="flex-1 flex--nogrow rotate icon-medium"
          [class.rotate-active]="expand.isOpen$ | async"
          [name]="SvgLibraryIcon.CHEVRON_DOWN"
        ></fcom-icon>
      </div>
      <div class="content" *ngIf="notification?.text1?.label | finLocalization as notificationSecondaryText">
        <div
          *ngIf="expand.isOpen$ | async"
          class="font-body-1 ps-xsmall-t"
          [innerHTML]="notificationSecondaryText | resolveLinks | finSafe: 'html'"
        ></div>
      </div>
    </fin-expand>
  </fcom-notification>
</ng-container>
