import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';

import {
  Amount,
  GroupedServices,
  SeatCharacteristics,
  ServiceCatalogService,
  ServicePassenger,
  TravelerService,
} from '@fcom/dapi/interfaces';
import {
  Category,
  FinnairAmount,
  FinnairBoundItem,
  FinnairLocation,
  FinnairPassengerItem,
  FinnairPassengerServiceSelectionItem,
  FinnairServiceCatalogEligibilityItem,
  SeatInfo,
  SeatPrice,
  SubCategory,
} from '@fcom/dapi/api/models';
import { NotificationTheme } from '@fcom/ui-components';
import { SelectionPhase } from '@fcom/common/interfaces/booking';

import { ConfirmModalTranslationKeys } from '../components';

export interface ExtendedServiceSelectionItem extends FinnairPassengerServiceSelectionItem {
  category: Category;
  flightIds: string[];
  passengerId: string;
  fragmentId?: string;
  cabins?: string;
}

export interface UiMeal extends ServiceCatalogService {
  selected$: Observable<boolean>;
  globalId?: string;
  includedAndPaid?: boolean;
  itemCategory?: string[];
}

export interface TravelerWithMeals {
  traveler: ServicePassenger;
  meals: UiMeal[];
  selectedMeals$: Observable<UiMeal[]>;
  mealSelected$: Observable<boolean>;
  defaultMealVariant: string;
}

export interface TravelerWithMeal {
  traveler: ServicePassenger;
  defaultMealVariant: string;
  meal: UiMeal;
}

export enum ButtonState {
  PLUS = 'PLUS',
  MINUS = 'MINUS',
  OK = 'OK',
  UPDATING = 'UPDATING',
  DISABLED = 'DISABLED',
}

export interface AncillaryServiceParams {
  orderId?: string;
  cartId?: string;
  hash?: string;
}

export interface PurchasedServicesForOrder {
  [fragmentId: string]: PurchasedService[];
}

export interface PurchasedService {
  displayName?: string;
  title?: string;
  quantity: number;
  category: SubCategory;
  maxBaggageWeight?: number;
  seatNumber?: string;
  travelerId?: string;
  variant: string;
}

export interface UpdatedServicesForPassenger {
  [fragmentId: string]: {
    [travelerId: string]: {
      name: string;
      included: PurchasedService[];
      added: TravelerService[];
      size?: number;
    };
  };
}

export interface EligibilitiesForFragments {
  [fragmentId: string]: EligibilityWithCategory[];
}

export interface EligibilityWithCategory extends FinnairServiceCatalogEligibilityItem {
  category: Category;
  subCategory?: SubCategory;
  originalEligibility?: FinnairServiceCatalogEligibilityItem;
}

export interface ConfirmModalData {
  confirmationModalTranslationKeys?: ConfirmModalTranslationKeys;
  variant?: string;
  quantity?: number;
  flightOrBoundId?: string;
  travelerId?: string;
}

export interface ConfirmModal {
  open: boolean;
  confirmData?: ConfirmModalData;
}

export interface PriceAndFragment {
  price: Amount;
  fragmentId: string;
}

export interface ItineraryModal {
  open: boolean;
  data: {
    outbound$: Observable<FinnairBoundItem>;
    inbound$: Observable<FinnairBoundItem>;
    locations$: Observable<{ [id: string]: FinnairLocation }>;
    selectionPhase$: Observable<SelectionPhase>;
  };
}

export type ProductInputType = 'radio' | 'checkbox' | 'number';

export enum QuotaType {
  FREE = 'FREE',
  TOPPED = 'TOPPED',
  NO = 'NO',
  SELECTED = 'SELECTED',
}

export interface Quota {
  count: number | undefined;
  type: QuotaType;
}

export interface ExtendedService extends ServiceCatalogService {
  selected$: Observable<boolean>;
  buttonText$: Observable<string>;
  globalId: string;
  quota$: Observable<Quota>;
  useConfirmModal: boolean;
  showBlockingNotification: boolean;
  inputType: ProductInputType;
  paidQuantity: number;
  displayTotalPrice$: Observable<FinnairAmount>;
  paxLevelQuotaReached$: Observable<boolean>;
  quantity?: number;
}

export interface Notification {
  iconName: SvgLibraryIcon;
  theme: NotificationTheme;
  key: string;
  blockSelection: boolean;
  reason?: {
    key: string;
    data: Record<string, string>;
  };
  type?: NotificationType;
}

export type NotificationType = 'OPERATING_AIRLINE_SEAT';

export interface SeatChangeEvent {
  travelerId: string;
  flightId: string;
  seatNumber: string | null;
}
export interface SeatMapTraveler {
  id: string;
  traveler: FinnairPassengerItem;
  withInfant: boolean;
  withInfantFullName: string;
  seatNumber?: string;
  originalSeatNumber?: string;
  seat?: SeatInfo;
  price?: SeatPrice;
  paidSeat?: boolean;
  ticketedSeat?: boolean;
  modifiableSeat?: boolean;
  groupedServices: GroupedServices[];
  active?: boolean;
}

export interface SeatDetails {
  travelerId: string;
  seat: SeatInfo;

  seatNumber: string;

  price: SeatPrice;

  /**
   * @see SeatType
   */
  seatType: string;

  /**
   * The traveler id for whom the seat is already selected.
   */
  seatSelectedForTraveler?: string;

  exitRow?: boolean;
}

export const seatCharacteristicsOrder = [
  SeatCharacteristics.EXTRA_LEG_SPACE,
  SeatCharacteristics.EXIT,
  SeatCharacteristics.BASSINET,
  SeatCharacteristics.ECO_COMFORT,
  SeatCharacteristics.PREFERRED,
  SeatCharacteristics.FRONT_OF_CABIN,
  SeatCharacteristics.FRONT_ROW,
  SeatCharacteristics.PREMIUM,
];

export const seatTypeInfo = {
  regular: {
    className: 'seat-regular',
    cmsKey: 'regularSeat',
    label: 'seatMap.seatChips.regularSeat',
  },
  [SeatCharacteristics.EXIT]: {
    className: 'seat-exit',
    cmsKey: 'exitRow',
    label: 'seatMap.seatChips.exitRow',
  },
  [SeatCharacteristics.EXTRA_LEG_SPACE]: {
    className: 'seat-extra-legroom',
    cmsKey: 'extraLegroomSeat',
    label: 'seatMap.seatChips.extraLegroomSeat',
  },
  [SeatCharacteristics.BASSINET]: {
    className: 'seat-bassinet',
    cmsKey: 'bassinetSeat',
    label: 'seatMap.seatChips.bassinetSeat',
  },
  [SeatCharacteristics.FRONT_OF_CABIN]: {
    className: 'seat-front-of-cabin',
    cmsKey: 'frontRowSeat',
    label: 'seatMap.seatChips.frontRowSeat',
  },
  [SeatCharacteristics.FRONT_ROW]: {
    className: 'seat-front-of-cabin',
    cmsKey: 'frontRowSeat',
    label: 'seatMap.seatChips.frontRowSeat',
  },
  [SeatCharacteristics.ECO_COMFORT]: {
    className: 'seat-comfort',
    cmsKey: 'comfortSeat',
    label: 'seatMap.seatChips.comfortSeat',
  },
  [SeatCharacteristics.PREFERRED]: {
    className: 'seat-preferred',
    cmsKey: 'preferredSeat',
    label: 'seatMap.seatChips.preferredSeat',
  },
  [SeatCharacteristics.PREMIUM]: {
    className: 'seat-premium',
    cmsKey: 'premiumSeat',
    label: 'seatMap.seatChips.premiumSeat',
  },
};
