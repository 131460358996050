/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { catchErrorAsJson } from '@fcom/rx';
import { DapiBaseService } from '../dapi-base-service';
import { DapiHttpResponse } from '../dapi-http-response';
import { DapiRequestBuilder } from '../dapi-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AirCalendarList } from '../models/air-calendar-list';
import { Cabin } from '../models/cabin';
import { FinnairAirBoundsRequest } from '../models/finnair-air-bounds-request';
import { FinnairAirBoundsResponse } from '../models/finnair-air-bounds-response';
import { FinnairSearchParameters } from '../models/finnair-search-parameters';
import { OfferList } from '../models/offer-list';


/**
 * Offer api resource
 */
@Injectable({
  providedIn: 'root',
})
export class OffersService extends DapiBaseService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  /**
   * Path part for operation findAirBounds
   */
  static readonly FindAirBoundsPath = '/api/airBounds';

  /**
   * findAirBounds.
   *
   * Finds air bounds with given search parameters
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAirBounds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAirBounds$Response(rootUrl: string, params: {
    body: FinnairAirBoundsRequest
  },
  context?: HttpContext

): Observable<DapiHttpResponse<FinnairAirBoundsResponse>> {
    const rb = new DapiRequestBuilder(rootUrl, OffersService.FindAirBoundsPath, 'post');

    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<FinnairAirBoundsResponse>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * findAirBounds.
   *
   * Finds air bounds with given search parameters
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAirBounds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAirBounds(rootUrl: string, params: {
    body: FinnairAirBoundsRequest
  },
  context?: HttpContext

): Observable<FinnairAirBoundsResponse> {
    return this.findAirBounds$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<FinnairAirBoundsResponse>) => r.body as FinnairAirBoundsResponse)
    );
  }

  /**
   * Path part for operation findAirCalendar
   */
  static readonly FindAirCalendarPath = '/api/airCalendar';

  /**
   * findAirCalendar.
   *
   * Find flight offers for given origin, destination, dates and travelers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAirCalendar()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  findAirCalendar$Response(rootUrl: string, params: {

    /**
     * IATA code for the departure location; airport or city
     */
    departureLocationCode: string;

    /**
     * IATA code for the destination location; airport or city
     */
    destinationLocationCode: string;

    /**
     * Departure date in format yyyy-MM-dd
     */
    departureDate: string;

    /**
     * Return date in format yyyy-MM-dd
     */
    returnDate?: string;

    /**
     * Number of adults
     */
    adults?: number;

    /**
     * Number of children (age 2-11)
     */
    children?: number;

    /**
     * Number of C15 passengers (children, age 12-15)
     */
    c15s?: number;

    /**
     * Number of infants
     */
    infants?: number;

    /**
     * Number of travelers.
     */
    travelers?: string;

    /**
     * Cabin
     */
    cabin?: Cabin;

    /**
     * oneway
     */
    oneway?: boolean;

    /**
     * directFlights
     */
    directFlights?: boolean;

    /**
     * isAward
     */
    isAward?: boolean;

    /**
     * locale
     */
    locale?: string;

    /**
     * promoCode
     */
    promoCode?: string;
  },
  context?: HttpContext

): Observable<DapiHttpResponse<AirCalendarList>> {
    const rb = new DapiRequestBuilder(rootUrl, OffersService.FindAirCalendarPath, 'get');

    if (params) {
      rb.query('departureLocationCode', params.departureLocationCode, {});
      rb.query('destinationLocationCode', params.destinationLocationCode, {});
      rb.query('departureDate', params.departureDate, {});
      rb.query('returnDate', params.returnDate, {});
      rb.query('adults', params.adults, {});
      rb.query('children', params.children, {});
      rb.query('c15s', params.c15s, {});
      rb.query('infants', params.infants, {});
      rb.query('travelers', params.travelers, {});
      rb.query('cabin', params.cabin, {});
      rb.query('oneway', params.oneway, {});
      rb.query('directFlights', params.directFlights, {});
      rb.query('isAward', params.isAward, {});
      rb.query('locale', params.locale, {});
      rb.query('promoCode', params.promoCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<AirCalendarList>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * findAirCalendar.
   *
   * Find flight offers for given origin, destination, dates and travelers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAirCalendar$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  findAirCalendar(rootUrl: string, params: {

    /**
     * IATA code for the departure location; airport or city
     */
    departureLocationCode: string;

    /**
     * IATA code for the destination location; airport or city
     */
    destinationLocationCode: string;

    /**
     * Departure date in format yyyy-MM-dd
     */
    departureDate: string;

    /**
     * Return date in format yyyy-MM-dd
     */
    returnDate?: string;

    /**
     * Number of adults
     */
    adults?: number;

    /**
     * Number of children (age 2-11)
     */
    children?: number;

    /**
     * Number of C15 passengers (children, age 12-15)
     */
    c15s?: number;

    /**
     * Number of infants
     */
    infants?: number;

    /**
     * Number of travelers.
     */
    travelers?: string;

    /**
     * Cabin
     */
    cabin?: Cabin;

    /**
     * oneway
     */
    oneway?: boolean;

    /**
     * directFlights
     */
    directFlights?: boolean;

    /**
     * isAward
     */
    isAward?: boolean;

    /**
     * locale
     */
    locale?: string;

    /**
     * promoCode
     */
    promoCode?: string;
  },
  context?: HttpContext

): Observable<AirCalendarList> {
    return this.findAirCalendar$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<AirCalendarList>) => r.body as AirCalendarList)
    );
  }

  /**
   * Path part for operation findAirCalendar1
   */
  static readonly FindAirCalendar1Path = '/api/airCalendar';

  /**
   * findAirCalendar.
   *
   * Find flight offers for given origin, destination, dates and travelers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAirCalendar1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAirCalendar1$Response(rootUrl: string, params: {
    body: FinnairSearchParameters
  },
  context?: HttpContext

): Observable<DapiHttpResponse<AirCalendarList>> {
    const rb = new DapiRequestBuilder(rootUrl, OffersService.FindAirCalendar1Path, 'post');

    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<AirCalendarList>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * findAirCalendar.
   *
   * Find flight offers for given origin, destination, dates and travelers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAirCalendar1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAirCalendar1(rootUrl: string, params: {
    body: FinnairSearchParameters
  },
  context?: HttpContext

): Observable<AirCalendarList> {
    return this.findAirCalendar1$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<AirCalendarList>) => r.body as AirCalendarList)
    );
  }

  /**
   * Path part for operation findOfferList
   */
  static readonly FindOfferListPath = '/api/offerList';

  /**
   * findOfferList.
   *
   * Find flight offers for given origin, destination, dates and travelers for award
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOfferList()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  findOfferList$Response(rootUrl: string, params: {

    /**
     * IATA code for the departure location; airport or city
     */
    departureLocationCode: string;

    /**
     * IATA code for the destination location; airport or city
     */
    destinationLocationCode: string;

    /**
     * Departure date in format yyyy-MM-dd
     */
    departureDate: string;

    /**
     * Return date in format yyyy-MM-dd
     */
    returnDate?: string;

    /**
     * Number of adults
     */
    adults?: number;

    /**
     * Number of children (age 2-11)
     */
    children?: number;

    /**
     * Number of C15 passengers (children, age 12-15)
     */
    c15s?: number;

    /**
     * Number of infants
     */
    infants?: number;

    /**
     * Number of travelers.
     */
    travelers?: string;

    /**
     * Cabin
     */
    cabin?: Cabin;

    /**
     * oneway
     */
    oneway?: boolean;

    /**
     * directFlights
     */
    directFlights?: boolean;

    /**
     * isAward
     */
    isAward?: boolean;

    /**
     * locale
     */
    locale?: string;

    /**
     * promoCode
     */
    promoCode?: string;
  },
  context?: HttpContext

): Observable<DapiHttpResponse<OfferList>> {
    const rb = new DapiRequestBuilder(rootUrl, OffersService.FindOfferListPath, 'get');

    if (params) {
      rb.query('departureLocationCode', params.departureLocationCode, {});
      rb.query('destinationLocationCode', params.destinationLocationCode, {});
      rb.query('departureDate', params.departureDate, {});
      rb.query('returnDate', params.returnDate, {});
      rb.query('adults', params.adults, {});
      rb.query('children', params.children, {});
      rb.query('c15s', params.c15s, {});
      rb.query('infants', params.infants, {});
      rb.query('travelers', params.travelers, {});
      rb.query('cabin', params.cabin, {});
      rb.query('oneway', params.oneway, {});
      rb.query('directFlights', params.directFlights, {});
      rb.query('isAward', params.isAward, {});
      rb.query('locale', params.locale, {});
      rb.query('promoCode', params.promoCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<OfferList>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * findOfferList.
   *
   * Find flight offers for given origin, destination, dates and travelers for award
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOfferList$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  findOfferList(rootUrl: string, params: {

    /**
     * IATA code for the departure location; airport or city
     */
    departureLocationCode: string;

    /**
     * IATA code for the destination location; airport or city
     */
    destinationLocationCode: string;

    /**
     * Departure date in format yyyy-MM-dd
     */
    departureDate: string;

    /**
     * Return date in format yyyy-MM-dd
     */
    returnDate?: string;

    /**
     * Number of adults
     */
    adults?: number;

    /**
     * Number of children (age 2-11)
     */
    children?: number;

    /**
     * Number of C15 passengers (children, age 12-15)
     */
    c15s?: number;

    /**
     * Number of infants
     */
    infants?: number;

    /**
     * Number of travelers.
     */
    travelers?: string;

    /**
     * Cabin
     */
    cabin?: Cabin;

    /**
     * oneway
     */
    oneway?: boolean;

    /**
     * directFlights
     */
    directFlights?: boolean;

    /**
     * isAward
     */
    isAward?: boolean;

    /**
     * locale
     */
    locale?: string;

    /**
     * promoCode
     */
    promoCode?: string;
  },
  context?: HttpContext

): Observable<OfferList> {
    return this.findOfferList$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<OfferList>) => r.body as OfferList)
    );
  }

  /**
   * Path part for operation findOfferList1
   */
  static readonly FindOfferList1Path = '/api/offerList';

  /**
   * findOfferList.
   *
   * Find flight offers for given origin, destination, dates and travelers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOfferList1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findOfferList1$Response(rootUrl: string, params: {
    body: FinnairSearchParameters
  },
  context?: HttpContext

): Observable<DapiHttpResponse<OfferList>> {
    const rb = new DapiRequestBuilder(rootUrl, OffersService.FindOfferList1Path, 'post');

    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<OfferList>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * findOfferList.
   *
   * Find flight offers for given origin, destination, dates and travelers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOfferList1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findOfferList1(rootUrl: string, params: {
    body: FinnairSearchParameters
  },
  context?: HttpContext

): Observable<OfferList> {
    return this.findOfferList1$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<OfferList>) => r.body as OfferList)
    );
  }

}
