<ng-container *ngFor="let passengerFormGroup of passengerFormGroups; let i = index; let first = first; let last = last">
  <fin-passenger-info-renewed
    *ngIf="extendedPassengers$ | async as extendedPassengers"
    [passengerFormGroup]="passengerFormGroup"
    [extendedPassenger]="extendedPassengers[i]"
    [availableDays]="availableDays[i]"
    [allFrequentFlyerCards$]="allFrequentFlyerCards$"
    [profileType$]="profileType$"
    [selectableLeadPassengers$]="selectableLeadPassengers$"
    [showEdit]="showEdit"
    [controlData$]="controlData$"
    [isMainPax]="i === 0"
    [isEditButtonDisabled$]="isSaving$"
    [isEditButtonLoading$]="(travelerIdBeingModified$ | async) === extendedPassengers[i].id | finToObservable"
    [paxIndex]="i + 1"
    [ngClass]="{
      'pr-small-b': first,
      'pr-small-y': !first,
      'border-bottom-dashed': !last,
    }"
    (handleSave)="handleSave($event)"
    (backToPaxDetails)="backToPaxDetails()"
    data-hj-suppress
  ></fin-passenger-info-renewed>
</ng-container>
