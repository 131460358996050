import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ConfigService, SentryLogger } from '@fcom/core/services';

import { ETicketReceiptSubmissionData, ETicketReceiptResponseData, ETicketReceiptFormData } from '../interfaces';

@Injectable()
export class SubmitService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private sentryLogger: SentryLogger
  ) {}

  public eTicketReceipt(formValues: ETicketReceiptFormData): Observable<ETicketReceiptResponseData> {
    const { apiUrl, keyId } = this.configService.cfg.serviceForm.eTicketReceipt;

    const submitQuery: ETicketReceiptSubmissionData = {
      lastName: formValues.eTicketReceipt.lastName,
      recLoc: formValues.eTicketReceipt.reservationNumber.toUpperCase(), // API requires uppercase
    };

    const httpOptions = {
      headers: this.buildHeaders({ 'x-api-key': keyId }),
    };

    return this.http.post<ETicketReceiptResponseData>(apiUrl, submitQuery, httpOptions).pipe(
      map((response) => {
        if (response.delivered) {
          return response;
        }
        throw new Error(response.status.message);
      }),
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      catchError((err) => {
        if (err.message !== 'booking_not_found') {
          this.sentryLogger.warn('Error submitting eTicketReceipt request', { error: err });
        }
        return throwError(() => err);
      })
    );
  }

  private buildHeaders(params: object): HttpHeaders {
    return new HttpHeaders(
      Object.assign(
        {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
        params
      )
    );
  }
}
