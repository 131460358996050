import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, of, switchMap, take, withLatestFrom } from 'rxjs';

import { TripType } from '@fcom/common';
import { finShare } from '@fcom/rx';
import { NotificationTheme } from '@fcom/ui-components';
import { LanguageService } from '@fcom/ui-translate';
import { isNotBlank, isPresent } from '@fcom/core/utils';
import { Location } from '@fcom/core-api';
import { GlobalBookingFlight } from '@fcom/common/store';
import { BUS_CONNECTION_LOCATION_CODES, RAIL_AND_FLY_LOCATION_CODE } from '@fcom/core/constants';

import { BookingWidgetAppState, notificationWarnings } from '../../store';
import {
  NotificationData,
  NotificationSpecific,
  NotificationWarning,
  SeasonalNotificationData,
  WarningData,
} from '../../interfaces';
import { warningNotificationPriorityOrder } from '../../constants';

@Component({
  selector: 'fin-booking-widget-notifications',
  templateUrl: './booking-widget-notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingWidgetNotificationComponent implements OnInit {
  @Input()
  locations$: Observable<GlobalBookingFlight[]> = of([]);

  @Input()
  tripType$: Observable<TripType> = of(undefined);

  @Input()
  isAm = false;

  notifications$: Observable<NotificationData[]>;

  readonly SvgLibraryIcon = SvgLibraryIcon;

  constructor(
    private store$: Store<BookingWidgetAppState>,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    const warnings$ = this.store$.pipe(
      notificationWarnings(),
      withLatestFrom(this.locations$, this.tripType$),
      map(([warnings, locations, tripType]) =>
        Object.entries(warnings)
          .sort(([a], [b]) => warningNotificationPriorityOrder[a] - warningNotificationPriorityOrder[b])
          .map((entry) => this.mapWarningToNotification(entry, tripType, locations))
          .filter(Boolean)
      )
    );

    const otherNotifications$ = combineLatest([this.locations$, this.tripType$]).pipe(
      switchMap(([locations, tripType]) => {
        const destinationNotification$ = this.mapDestinationNotification(locations[0]?.destination);
        const busConnectionNotification = this.mapBusConnectionNotification(locations);
        const trainConnectionNotification = this.mapTrainConnectionNotification(locations);
        const amEarnPointsNotification = this.isAm ? this.mapAmEarnPointsNotification() : undefined;
        const showMaxNumberOfFlightsNotification =
          tripType === TripType.MULTICITY ? this.showMaxNumberOfFlightsNotification(locations) : undefined;
        const showNotConsecutiveNotification =
          tripType === TripType.MULTICITY ? this.showNotConsecutiveNotification(locations) : undefined;

        return destinationNotification$.pipe(
          map((destinationNotification) =>
            [
              busConnectionNotification,
              trainConnectionNotification,
              destinationNotification,
              amEarnPointsNotification,
              showMaxNumberOfFlightsNotification,
              showNotConsecutiveNotification,
            ].filter(Boolean)
          )
        );
      })
    );

    this.notifications$ = combineLatest([warnings$, otherNotifications$]).pipe(
      map(([warnings, otherNotifications]) => [...warnings, ...otherNotifications])
    );
  }

  private mapWarningToNotification(
    [type, warningData]: [string, WarningData],
    tripType: TripType,
    flights: GlobalBookingFlight[]
  ): NotificationData {
    if (!warningData.isActive) {
      return undefined;
    }

    switch (type) {
      case NotificationWarning.SEASONAL_ROUTE:
        return isPresent(warningData?.data) && flights[0]?.origin && flights[0]?.destination
          ? this.mapSeasonalNotification(warningData.data, flights)
          : null;
      case NotificationWarning.NO_FLIGHTS:
        return this.mapNoFlightNotification(tripType, flights, false);
      case NotificationWarning.NO_AWARD_FLIGHTS:
        return this.mapNoFlightNotification(tripType, flights, true);
      case NotificationWarning.NO_FLIGHTS_FOR_ROUTE:
        return this.mapNoAwardFlightForRouteNotification(false);
      case NotificationWarning.NO_AWARD_FLIGHTS_FOR_ROUTE:
        return this.mapNoAwardFlightForRouteNotification(true);
      case NotificationWarning.GENERAL:
        return this.mapFlightGeneralNotification();
      default:
        return undefined;
    }
  }

  private mapDestinationNotification(destination: Location): Observable<NotificationData> {
    if (!isPresent(destination?.countryCode)) {
      return of(undefined);
    }

    return this.languageService.translate(`dynamicNotifications.destination.${destination.countryCode}`).pipe(
      take(1),
      map((notificationText: string) =>
        isNotBlank(notificationText)
          ? {
              type: NotificationSpecific.DESTINATION_SPECIFIC,
              theme: NotificationTheme.INFO,
              text: { label: `dynamicNotifications.destination.${destination.countryCode}` },
            }
          : undefined
      ),
      finShare()
    );
  }

  private mapSeasonalNotification(
    seasonalDataNotification: SeasonalNotificationData,
    flights: GlobalBookingFlight[]
  ): NotificationData {
    return {
      type: NotificationWarning.SEASONAL_ROUTE,
      theme: NotificationTheme.INFO,
      text: {
        label: `timetable.seasonalNotification.${
          Object.keys(seasonalDataNotification).length <= 2 ? 'singular' : 'plural'
        }`,
        params: {
          ...seasonalDataNotification,
          origin: flights[0].origin.cityName,
          destination: flights[0].destination.cityName,
        },
      },
      link: {
        url: `/${this.languageService.langValue}/timetables?dest=${flights[0].destination.locationCode}&origin=${flights[0].origin.locationCode}`,
        text: 'routeNotification.timetable.forRouteLinkText',
      },
    };
  }

  private mapFlightGeneralNotification(): NotificationData {
    return {
      type: NotificationWarning.GENERAL,
      theme: NotificationTheme.WARNING,
      text: {
        label: 'errors.booking.title',
      },
      text1: {
        label: 'errors.booking.description',
      },
    };
  }

  private mapNoAwardFlightForRouteNotification(isAward: boolean): NotificationData {
    return {
      type: isAward ? NotificationWarning.NO_AWARD_FLIGHTS_FOR_ROUTE : NotificationWarning.NO_FLIGHTS_FOR_ROUTE,
      theme: NotificationTheme.WARNING,
      text: {
        label: isAward ? 'bookingSearch.errors.noAwardFlightsForRoute' : 'bookingSearch.errors.noFlightsForRoute',
      },
    };
  }

  private mapNoFlightNotification(
    tripType: TripType,
    flights: GlobalBookingFlight[],
    isAward: boolean
  ): NotificationData {
    return {
      type: isAward ? NotificationWarning.NO_AWARD_FLIGHTS : NotificationWarning.NO_FLIGHTS,
      theme: NotificationTheme.WARNING,
      text: {
        label: isAward ? 'bookingSearch.errors.noAwardFlightsFound' : 'bookingSearch.errors.noFlightsFound',
      },
      ...(tripType !== TripType.MULTICITY &&
        !isAward && {
          text1: { label: 'routeNotification.timetable.checkTimeTableText' },
        }),
      link: {
        openInNewTab: true,
        ...(tripType === TripType.MULTICITY && { external: true }),
        url:
          tripType !== TripType.MULTICITY
            ? `/${this.languageService.langValue}/timetables?dest=${flights[0].destination.locationCode}&origin=${flights[0].origin.locationCode}`
            : 'bookingSearch.errors.multiCity.seeMoreLink.url',
        text:
          tripType !== TripType.MULTICITY
            ? 'routeNotification.timetable.forRouteLinkText'
            : 'bookingSearch.errors.multiCity.seeMoreLinkText',
      },
    };
  }

  private mapBusConnectionNotification(flights: GlobalBookingFlight[]): NotificationData {
    const isBusConnectionNeeded = flights.some((flight) => {
      return ['origin', 'destination'].some((key) => BUS_CONNECTION_LOCATION_CODES.includes(flight[key]?.locationCode));
    });

    if (!isBusConnectionNeeded) {
      return undefined;
    }

    return {
      type: NotificationSpecific.BUS_CONNECTION,
      theme: NotificationTheme.INFO,
      text: { label: 'routeNotification.busConnections.notificationText' },
      link: {
        openInNewTab: true,
        url: 'routeNotification.busConnections.link.url',
        text: 'routeNotification.busConnections.linkText',
      },
    };
  }

  private mapTrainConnectionNotification(flights: GlobalBookingFlight[]): NotificationData {
    const showTrainNotification = flights.some((flight) => {
      return ['origin', 'destination'].some((key) => RAIL_AND_FLY_LOCATION_CODE.includes(flight[key]?.locationCode));
    });

    if (!showTrainNotification) {
      return undefined;
    }

    return {
      type: NotificationSpecific.TRAIN_CONNECTION,
      icon: SvgLibraryIcon.TRAIN,
      theme: NotificationTheme.INFO,
      text: { label: 'routeNotification.trainConnections.notificationText' },
      link: {
        openInNewTab: true,
        url: 'routeNotification.trainConnections.link.url',
        text: 'routeNotification.trainConnections.linkText',
      },
    };
  }

  private mapAmEarnPointsNotification(): NotificationData {
    return {
      type: NotificationSpecific.AM_EARN_POINTS,
      theme: NotificationTheme.INFO,
      text: { label: 'aurinkomatkat.notification.teaser' },
      text1: { label: 'aurinkomatkat.notification.content' },
    };
  }

  private showMaxNumberOfFlightsNotification(flights: GlobalBookingFlight[]): NotificationData {
    const hasReachedMaxNumberOfFlights = flights.length >= 6;

    return hasReachedMaxNumberOfFlights
      ? {
          type: NotificationSpecific.MULTICITY_MAX_NUMBER_OF_FLIGHTS,
          theme: NotificationTheme.INFO,
          text: { label: 'multiCityNotification.maxNumberOfFlights.notificationTitle' },
          text1: { label: 'multiCityNotification.maxNumberOfFlights.notificationText' },
        }
      : undefined;
  }

  private showNotConsecutiveNotification(flights: GlobalBookingFlight[]): NotificationData {
    const isAfterNextDepartureDate: boolean = flights.some((flight, i) => {
      if (!flight.departureDate) {
        return false;
      }

      const nextFlight = flights[i + 1];

      if (flight.departureDate.gt(nextFlight?.departureDate)) {
        return true;
      }

      return false;
    });

    return isAfterNextDepartureDate
      ? {
          type: NotificationSpecific.MULTICITY_NOT_CONSECUTIVE,
          theme: NotificationTheme.ALERT,
          text: { label: 'multiCityNotification.notConsecutiveDates.notificationText' },
        }
      : undefined;
  }
}
