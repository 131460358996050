import { Component, computed, input, InputSignal } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { CmsQueryListItem, CmsTaxonomyGeneral } from '@fcom/core-api';

@Component({
  selector: 'fin-news-feed-item',
  templateUrl: './news-feed-item.component.html',
  styleUrl: './news-feed-item.component.scss',
})
export class NewsFeedItemComponent {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  content: InputSignal<CmsQueryListItem> = input.required<CmsQueryListItem>();
  companyNews: InputSignal<boolean> = input(false);
  targetBlank = computed((): boolean => {
    if (this.companyNews()) {
      return true;
    } else {
      return this.content().subjectTaxonomyTags.includes(CmsTaxonomyGeneral.EMERGENCY_IN_OPERATIONS_COMPANY);
    }
  });
  target = computed((): string => {
    const itemHasCompanyTag: boolean = this.content().subjectTaxonomyTags.includes(
      CmsTaxonomyGeneral.EMERGENCY_IN_OPERATIONS_COMPANY
    );
    /*
     * Teasers & external link content items do not have a way to set custom dates, only articles.
     * So for the external company news links, tag is added to tell the component to fetch the text (which should render as the link)
     * from the teaserText and use that for the target/href.
     * Custom date is important for being able to control the externallyDisplayedDate used for query lists and personalised search.
     */
    if (itemHasCompanyTag || this.companyNews()) {
      return this.content().teaserText.replace(/<[^>]*>/g, '');
    } else {
      return this.content().url;
    }
  });
}
