import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';

import { filter, map, Observable, of, take } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

import { Airport, BookingLocationCodes } from '@fcom/common/interfaces/booking';
import { formatTime } from '@fcom/common/pipes/time.pipe';
import { ScrollHandleContainerComponent } from '@fcom/common/components/scroll-handle-container/scroll-handle-container.component';
import { Step, TripType } from '@fcom/common/interfaces';
import { RootPaths } from '@fcom/core/constants';
import { GlobalBookingFlight, LocationPair } from '@fcom/common/store';
import { LoginGender } from '@fcom/core-api/login';
import {
  Category,
  FinnairAmount,
  FinnairBoundGroup,
  FinnairBoundItem,
  FinnairCabinClass,
  FinnairCart,
  FinnairDisruptedBoundItem,
  FinnairGender,
  FinnairItineraryItem,
  FinnairItineraryItemFlight,
  FinnairItineraryItemFlightStatus,
  FinnairItineraryItemType,
  FinnairLocation,
  FinnairOrder,
  FinnairOtherInformation,
  FinnairPassengerItem,
  FinnairPassengerServiceItem,
  FinnairPassengerServiceSelectionItem,
  FinnairServiceBoundItem,
  FinnairServiceCatalogEligibilityCategory,
  FinnairServiceCatalogItemV3,
  FinnairServiceCatalogV3,
  FinnairServiceItem,
  FinnairServices,
  FinnairServiceSegmentItem,
  FinnairTotalPricesDetails,
  FinnairTravelEndpoint,
  ItineraryItem,
  ItineraryItemFlight,
  ItineraryItemLayover,
  SubCategory,
} from '@fcom/dapi/api/models';
import {
  Amount,
  CustomServiceType,
  DapiHttpErrorResponse,
  FinnairServiceItemWithUpsell,
  GroupedServices,
  LegEndpoint,
  ServiceStatus,
  Upsell,
} from '@fcom/dapi/interfaces';
import {
  DateFormat,
  groupBy,
  isEmptyObject,
  isFinnairNorraOrWetLease,
  LocalDate,
  rangeFrom,
  TzDate,
} from '@fcom/core/utils';
import { matchesLastPathSegment, pathIsWithinHardcodedPath } from '@fcom/core/utils/app-url-utils';
import { Bound } from '@fcom/dapi/api/models/bound';
import { BOOKING_STEPS } from '@fcom/common/config/booking-config';
import { TranslatedCountryCode } from '@fcom/common/services';
import { getIconForServiceCategory } from '@fcom/dapi/utils';

import {
  BUS_AIRCRAFT_CODE,
  CartOrOrder,
  FinnairBoundItemWithLocation,
  FinnairServiceItemWithIcon,
  GENDER_OPTIONS,
  Title,
} from '../interfaces';
import { isBoundBasedCategory, isJourneyBasedCategory } from '../modules/ancillaries/utils/category.utils';
import { combineServices, getPassengerTierLevel } from '../modules/ancillaries/utils/ancillary.utils';
import { SmpProduct } from '../modules';
import { ButtonState } from '../modules/ancillaries/interfaces';

export const toAmount = (amount): Amount => ({
  amount: String(amount.amount),
  currencyCode: amount.currencyCode,
});

export const isInBookingPurchase = (url: string): boolean =>
  pathIsWithinHardcodedPath(url, RootPaths.BOOKING_ROOT) &&
  (matchesLastPathSegment(url, BOOKING_STEPS.PURCHASE_SUCCESS.path) ||
    matchesLastPathSegment(url, BOOKING_STEPS.CHECKOUT.path));

export const isInMmb = (url: string): boolean =>
  pathIsWithinHardcodedPath(url, RootPaths.MANAGE_BOOKING_ROOT) &&
  !matchesLastPathSegment(url, RootPaths.MANAGE_BOOKING_ROOT);

export const isTravelReady = (url: string): boolean => matchesLastPathSegment(url, RootPaths.TRAVEL_READY);

export const isMmbStep = (url: string, step: Step): boolean =>
  pathIsWithinHardcodedPath(url, RootPaths.MANAGE_BOOKING_ROOT) && matchesLastPathSegment(url, step.path);

export const isChangeFlowStep = (url: string, step: Step): boolean =>
  url.includes([RootPaths.VOLUNTARY_CHANGE_ROOT, step.path].join('/'));

export const isBoundFlown = (bound: FinnairBoundItem | FinnairDisruptedBoundItem): boolean =>
  allFlightsHaveStatuses(bound, [FinnairItineraryItemFlightStatus.FLOWN]);

export const isBoundOrFlightConfirmedOrCheckedIn = (
  boundOrFlight: FinnairBoundItem | FinnairItineraryItemFlight
): boolean => {
  const confirmedOrCheckedIn = [
    FinnairItineraryItemFlightStatus.CONFIRMED,
    FinnairItineraryItemFlightStatus.CHECKED_IN,
  ];
  return isBound(boundOrFlight)
    ? allFlightsHaveStatuses(boundOrFlight, confirmedOrCheckedIn)
    : confirmedOrCheckedIn.includes(boundOrFlight.status);
};

const allFlightsHaveStatuses = (
  bound: FinnairBoundItem | FinnairDisruptedBoundItem,
  statuses: FinnairItineraryItemFlightStatus[]
): boolean => bound.itinerary.filter(isFlight).every((i) => statuses.includes(i.status));

export const isBound = (
  toBeDetermined: FinnairBoundItem | FinnairDisruptedBoundItem | FinnairItineraryItemFlight | Bound
): toBeDetermined is FinnairBoundItem | FinnairDisruptedBoundItem | Bound =>
  toBeDetermined && !!(toBeDetermined as FinnairBoundItem | FinnairDisruptedBoundItem | Bound).itinerary;

const asTimeAndWeekDay = (dateTime: string, translations: unknown): string =>
  `${formatTime(dateTime)} ${new DateFormat(translations).format(dateTime, DateFormat.WEEKDAY_SHORT)}`;

export const legEndpointToUfoAirport = (
  legEndpoint: LegEndpoint | FinnairTravelEndpoint,
  translations: unknown,
  locations?: { [key: string]: FinnairLocation }
): Airport => {
  if (!legEndpoint) {
    return undefined;
  }
  const { dateTime, locationCode } = legEndpoint;
  const location = locations?.[locationCode];
  return {
    dateTime: asTimeAndWeekDay(dateTime, translations),
    airportCode: locationCode,
    city: location?.cityName ?? (legEndpoint as LegEndpoint).cityName,
    airport: location?.name ?? (legEndpoint as LegEndpoint).name,
  };
};

/**
 * Only feasible for cart model checking
 * @param totalPrices
 */
export const isAwardBooking = (totalPrices: FinnairTotalPricesDetails): boolean =>
  !!totalPrices?.total?.total?.totalPoints;

export const numberOfDaysInMonth = (year: number): number[] => {
  return rangeFrom(1, 12).map((m) => LocalDate.getAmountOfDaysInMonth(year, m));
};

export const operatingAirlineCodeOrBusIndicator = (flight: FinnairItineraryItemFlight): string =>
  isBus(flight)
    ? BUS_AIRCRAFT_CODE.toLowerCase()
    : flight.operatingAirline?.name === 'Iberia for Finnair'
      ? 'IB'
      : flight.operatingAirline?.code;

const isBus = (flight: FinnairItineraryItemFlight): boolean =>
  flight.aircraft?.code?.toUpperCase() === BUS_AIRCRAFT_CODE;

export const getTails = (
  boundOrFlight: FinnairBoundItem | FinnairDisruptedBoundItem | FinnairItineraryItemFlight
): string[] => {
  const tails: string[] = isBound(boundOrFlight)
    ? boundOrFlight.itinerary
        .filter(isFlight)
        .flatMap((itinerary: FinnairItineraryItemFlight) => operatingAirlineCodeOrBusIndicator(itinerary))
    : [operatingAirlineCodeOrBusIndicator(boundOrFlight)];
  return tails.filter((tail) => tail);
};

export const isByBusOnly = (
  boundOrFlight: FinnairBoundItem | FinnairDisruptedBoundItem | FinnairItineraryItemFlight
): boolean => {
  return isBound(boundOrFlight)
    ? boundOrFlight.itinerary.filter(isFlight).every((itinerary: FinnairItineraryItemFlight) => isBus(itinerary))
    : isBus(boundOrFlight);
};

export function isUsingSAF(
  boundOrFlight:
    | FinnairBoundItemWithLocation
    | FinnairBoundItem
    | FinnairDisruptedBoundItem
    | Bound
    | FinnairItineraryItemFlight
): boolean {
  return isBound(boundOrFlight)
    ? boundOrFlight.itinerary.some((itineraryItem) => isSegmentUsingSAF(itineraryItem))
    : isSegmentUsingSAF(boundOrFlight);
}

export function isSegmentUsingSAF(segment: FinnairItineraryItem | ItineraryItemFlight): boolean {
  if (!isFlight(segment)) {
    return false;
  }
  const opAirline = segment.operatingAirline;
  return !isBus(segment) && isFinnairNorraOrWetLease(opAirline.name, opAirline.code);
}

/**
 * Priority services aren't available if first leg is on bus and second is code share flight
 * @param itineraries
 * @returns {boolean}
 */
export const priorityServicesAvailable = (itineraries: (ItineraryItemFlight | ItineraryItemLayover)[]): boolean => {
  const legs: FinnairItineraryItemFlight[] = itineraries.filter(isFlight);
  if (legs.length <= 1) {
    return true;
  }
  const airLine = legs[1].operatingAirline;
  return !isByBusOnly(legs[0]) || isFinnairNorraOrWetLease(airLine?.name, airLine?.code);
};

export const getDapiErrorKey = (err: unknown): string => {
  const error = (err as DapiHttpErrorResponse)?.error;
  return `${error?.key ?? 'UNKNOWN_ERROR'}_${error?.errorType ?? 'UNKNOWN_TYPE'}`;
};

export const loginGenderToGenderOption = (loginGender: LoginGender): string => {
  const genderMap = {
    [LoginGender.MALE]: GENDER_OPTIONS[0].value,
    [LoginGender.FEMALE]: GENDER_OPTIONS[1].value,
  };

  return genderMap[loginGender];
};

export const corporateSalutationToGenderOption = (title: string): string | undefined => {
  switch (title) {
    case 'Mr.':
      return 'mr';
    case 'Mrs.':
    case 'Ms.':
      return 'ms';
    default:
      return undefined;
  }
};

export const finnairGenderToGenderOption = (finnairGender: FinnairGender): 'mr' | 'ms' => {
  const genderMap = {
    [FinnairGender.MALE]: GENDER_OPTIONS[0].value,
    [FinnairGender.FEMALE]: GENDER_OPTIONS[1].value,
  };

  return genderMap[finnairGender];
};

export const titleToLoginGender = (title: string): LoginGender => {
  const genderMap = {
    [Title.MR]: LoginGender.MALE,
    [Title.MS]: LoginGender.FEMALE,
    [Title.MRS]: LoginGender.FEMALE,
  };

  return genderMap[title];
};

export const titleToGender = (title: string): FinnairGender | undefined => {
  const genderMap = {
    MR: FinnairGender.MALE,
    MS: FinnairGender.FEMALE,
    MRS: FinnairGender.FEMALE,
  };

  return genderMap[title];
};

export const getCabinClassShortCode = (cabinClass: string): string => {
  const map = {
    [FinnairCabinClass.ECONOMY]: 'E',
    [FinnairCabinClass.ECOPREMIUM]: 'PE',
    [FinnairCabinClass.BUSINESS]: 'B',
    [FinnairCabinClass.FIRST]: 'F',
  };
  return map[cabinClass] || '';
};

export const getBrandNameShortCode = (brandName: string): string => {
  const name = brandName?.toUpperCase() || '';
  if (name.includes('LIGHT')) {
    return 'L';
  } else if (name.includes('CLASSIC')) {
    return 'C';
  } else if (name.includes('FLEX')) {
    return 'F';
  }
  return '';
};

export const adjustFareFamilyOptionHeight = (
  scrollContainer: ScrollHandleContainerComponent,
  fareHeaderSelectors: string,
  property: string
): void => {
  if (!scrollContainer) {
    return;
  }

  const fareHeaders: HTMLElement[] = Array.from<HTMLElement>(
    scrollContainer.container.nativeElement.querySelectorAll(fareHeaderSelectors)
  );
  fareHeaders.forEach((e) => {
    e.style[property] = 'auto';
  });

  const itemHeights = fareHeaders.map((e) => e.getBoundingClientRect().height);
  const maxHeight = Math.max(...itemHeights);
  fareHeaders.forEach((e) => {
    e.style[property] = `${maxHeight}px`;
  });
};

export const getTripTypeFromBookingLocationCodes = (locations: BookingLocationCodes[]): TripType => {
  if (locations.length === 1) {
    return TripType.ONEWAY;
  }

  if (
    locations.length === 2 &&
    locations[0].originLocationCode === locations[1].destinationLocationCode &&
    locations[0].destinationLocationCode === locations[1].originLocationCode
  ) {
    return TripType.RETURN;
  }

  return TripType.MULTICITY;
};

export const getLocationPairFromGlobalBookingFlights = (flights: GlobalBookingFlight[]): LocationPair[] => {
  return flights.map((flight) => {
    return {
      origin: flight.origin,
      destination: flight.destination,
    };
  });
};

export const getTripTypeFromGlobalBookingFlights = (flights: GlobalBookingFlight[]): TripType => {
  return getTripTypeFromBookingLocationCodes(
    flights.map((flight) => {
      return {
        originLocationCode: flight.origin?.locationCode,
        destinationLocationCode: flight.destination?.locationCode,
      };
    })
  );
};

export const getTripTypeFromBounds = (bounds: FinnairBoundItem[]): TripType => {
  return getTripTypeFromBookingLocationCodes(
    bounds.map((bound) => {
      return {
        originLocationCode: bound.departure.locationCode,
        destinationLocationCode: bound.arrival.locationCode,
      };
    })
  );
};

/**
 * Determine if there is cancelation with no alternative
 * @param bound
 */
export const isCancelledFlightWithNoAlternative = (bound: FinnairBoundItem): boolean => {
  return (
    bound.itinerary
      .filter((flight) => flight.type === FinnairItineraryItemType.FLIGHT)
      .some((flight: FinnairItineraryItemFlight) => flight.status === FinnairItineraryItemFlightStatus.CANCELED) &&
    !bound.disruptedBound
  );
};

/**
 * Determine if there is disrupted bound in the booking
 * @param booking Information about the whole booking
 */
export const bookingHasDisruption = (booking: FinnairOrder): boolean =>
  (booking?.eligibilities.acknowledge.some((eligility) => eligility.isAllowedToUse) ||
    booking?.bounds.some((bound) => isCancelledFlightWithNoAlternative(bound))) ??
  false;

export const bookingHasCoverService = (booking: FinnairOrder): boolean =>
  booking?.services?.included.some((includedService) => includedService.category === Category.COVER);

export const getAllFlightsFromCartOrOrder = (cartData: CartOrOrder): FinnairItineraryItemFlight[] =>
  cartData.bounds?.reduce((all, bound) => all.concat(bound.itinerary.filter(isFlight)), []);

export const isTourOperator = (hasOtherInformation: { otherInformation: FinnairOtherInformation }): boolean =>
  !!hasOtherInformation?.otherInformation?.tourOperator;

export const isNotIncludedService = (
  service: FinnairPassengerServiceSelectionItem
): service is FinnairPassengerServiceSelectionItem => {
  return !service.includedInTicketType && !service.includedInTierBenefit;
};

/**
 * Check if a ItineraryItem is a flight or bus by checking the itineraryItem.type property.
 */
export const isFlight = (value: FinnairItineraryItem | ItineraryItem): value is FinnairItineraryItemFlight => {
  return value.type === FinnairItineraryItemType.FLIGHT;
};

export const hasBusSegment = (order: FinnairOrder): boolean =>
  order.bounds.some((bound) =>
    bound.itinerary.filter(isFlight).some((i: FinnairItineraryItemFlight) => isByBusOnly(i))
  );

const isCatalogHavingService = (
  servicesToSell: FinnairServiceCatalogItemV3['services'],
  eligibilities: FinnairServiceCatalogEligibilityCategory['services'],
  bound: FinnairBoundItem
): boolean => {
  const servicesForPaxes = servicesToSell[bound.id]
    ? [servicesToSell[bound.id]]
    : bound.itinerary
        .filter(isFlight)
        .map((flight) => servicesToSell[flight.id])
        .filter(Boolean);

  return servicesForPaxes.some((servicesForPax) => {
    return Object.entries(servicesForPax).some(([paxId, paxServices]) => {
      return paxServices.length > 0 && isBoundHavingEligibilityForPax(eligibilities, bound, paxId);
    });
  });
};

const isBoundHavingEligibilityForPax = (
  eligibilities: FinnairServiceCatalogEligibilityCategory['services'],
  bound: FinnairBoundItem,
  paxId: string
): boolean => {
  const realEligibilities = eligibilities[bound.id]
    ? [eligibilities[bound.id]]
    : bound.itinerary
        .filter(isFlight)
        .map((flight) => eligibilities[flight.id])
        .filter(Boolean);
  return realEligibilities.some((eligibilityForPax) => eligibilityForPax[paxId]?.isAllowedToUse);
};

export const isBoundAirDiscount = (bound: FinnairBoundGroup): boolean =>
  Boolean(bound.originalCheapestPrice) && bound.fareFamilies.every((ff) => ff.discountReasonCode);

export const shouldWeSellForCategory = (category: Category, order: FinnairOrder, bound?: FinnairBoundItem): boolean => {
  const servicesToSell = order.serviceCatalog?.categories?.find((c) => c.category === category)?.services ?? {};
  const eligibilities =
    order.eligibilities.serviceCatalog?.categories?.find((c) => c.category === category)?.services ?? {};

  const bounds = bound ? [bound] : order.bounds;

  return bounds.some((currentBound) => isCatalogHavingService(servicesToSell, eligibilities, currentBound));
};

export const passengersMissingCategoryService = (
  category: Category,
  cartOrOrder: CartOrOrder,
  bound?: FinnairBoundItem | FinnairDisruptedBoundItem,
  useEvery: boolean = true
): boolean => {
  const eligibilities =
    cartOrOrder.eligibilities.serviceCatalog?.categories?.find((c) => c.category === category)?.services ?? {};

  const fragmentIds = isBoundBasedCategory(category)
    ? bound
      ? [bound.id]
      : cartOrOrder.bounds.map((b) => b.id)
    : (bound ? bound.itinerary.filter(isFlight) : cartOrOrder.bounds.flatMap((b) => b.itinerary).filter(isFlight)).map(
        ({ id }) => id
      );

  const eligibilitiesToUse = Object.entries(eligibilities).reduce((all, [fragmentId, eligibility]) => {
    if (fragmentIds.includes(fragmentId)) {
      all[fragmentId] = eligibility;
    }
    return all;
  }, {});

  const servicesForSegments =
    combineServices([cartOrOrder.services.included, cartOrOrder.services.unpaid])
      ?.find((service) => service.category === category)
      ?.bounds.filter((b) => (bound ? b.id === bound.id : true))
      .flatMap((b) => b.segments) ?? [];

  const predicateFunc = ([fragmentId, paxEligibilityForFlight]) => {
    const eligiblePaxIds = Object.keys(paxEligibilityForFlight)
      .filter((paxId) => paxEligibilityForFlight[paxId].isAllowedToUse)
      .sort()
      .join('');

    // for bounds we check the first segment
    const services = !isBoundBasedCategory(category)
      ? servicesForSegments.find((segment) => segment.id === fragmentId)
      : servicesForSegments[0];

    const paxIdsHavingService =
      services?.passengers
        .filter((p) => p.services.filter(isNotIncludedService).length > 0)
        .flatMap((p) => p.id)
        .sort()
        .join('') ?? '';

    return paxIdsHavingService !== eligiblePaxIds;
  };

  const noServiceForAllPassengerOnAnySegment = useEvery
    ? Object.entries(eligibilitiesToUse).every(predicateFunc)
    : Object.entries(eligibilitiesToUse).some(predicateFunc);

  return (
    Object.values(eligibilitiesToUse)
      .flatMap((s) => Object.values(s))
      .some((e) => e.isAllowedToUse) && noServiceForAllPassengerOnAnySegment
  );
};

export const servicePresentedInServices = (
  services: FinnairServices,
  category: Category,
  whereToSearchArray: Exclude<keyof FinnairServices, 'servicesOrder'>[] = ['included', 'pending', 'unpaid']
): boolean =>
  whereToSearchArray
    .reduce((acc, key) => [...acc, ...(services[key]?.flatMap(({ category }) => category) || [])], [])
    .includes(category);

export const checkIfAdditionalEmailExists = (
  value: string,
  additionalEmails$: Observable<string[]>
): Observable<boolean> => {
  return additionalEmails$.pipe(
    take(1),
    map((emails) => emails?.includes(value))
  );
};

export const additionalEmailValidator = (additionalEmails$: Observable<string[]>): AsyncValidatorFn => {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return checkIfAdditionalEmailExists(control.value, additionalEmails$).pipe(
      map((result) => {
        return result ? { duplicate: true } : null;
      })
    );
  };
};

/**
 * Splits phone number into country code and national number
 * E.g. +358401234567 -> ['FI|358', '401234567']
 * @param phoneNumberWithCountryCode
 * @param countryCodes
 */
export const splitPhoneNumber = (
  phoneNumberWithCountryCode: string,
  countryCodes: TranslatedCountryCode[]
): [string, string] => {
  if (!phoneNumberWithCountryCode) {
    return [undefined, phoneNumberWithCountryCode];
  }

  const onlyNumbers = phoneNumberWithCountryCode.replace(/\D/g, '');

  const start = 0;
  let found = false,
    len = 1,
    countryCode: TranslatedCountryCode;
  while (!found && len < 4) {
    const predicate = onlyNumbers.substring(start, start + len);
    countryCode = countryCodes.find((cc) => cc.phonePrefix === predicate);
    found = !!countryCode;
    len++;
  }
  if (found) {
    return [
      `${countryCode.countryCode}|${countryCode.phonePrefix}`,
      onlyNumbers.slice(onlyNumbers.indexOf(countryCode.phonePrefix) + countryCode.phonePrefix.length),
    ];
  }
  return [undefined, phoneNumberWithCountryCode];
};

export const getServiceStatus = (
  service: FinnairPassengerServiceSelectionItem,
  isCarrierConnect = false,
  includeAssignAtAirportStatus = false
): ServiceStatus.CONFIRMED | ServiceStatus.PENDING_PAYMENT | ServiceStatus.ASSIGN_AT_AIRPORT => {
  return service.includedInTierBenefit || service.ticketed || service.includedInTicketType
    ? isAssignAtAirportSeat(service, includeAssignAtAirportStatus, isCarrierConnect)
      ? ServiceStatus.ASSIGN_AT_AIRPORT
      : ServiceStatus.CONFIRMED
    : ServiceStatus.PENDING_PAYMENT;
};

export const isAssignAtAirportSeat = (
  service: FinnairPassengerServiceSelectionItem,
  includeAssignAtAirportStatus = false,
  isCarrierConnect = false
): boolean => {
  return (
    service.subCategory === SubCategory.SEAT &&
    includeAssignAtAirportStatus &&
    isNotIncludedService(service) &&
    !isCarrierConnect &&
    !service.seatNumber
  );
};

const getIconForService = (
  service: FinnairPassengerServiceSelectionItem,
  isCarrierConnect = false,
  includeAssignAtAirportStatus = false
): 'checkmark' | 'shopping-cart' | 'time-zone' =>
  service.includedInTierBenefit || service.ticketed || service.includedInTicketType
    ? isAssignAtAirportSeat(service, includeAssignAtAirportStatus, isCarrierConnect)
      ? 'time-zone'
      : 'checkmark'
    : 'shopping-cart';

const isSameServiceItem = (
  servItem1: FinnairPassengerServiceSelectionItem,
  servItem2: FinnairPassengerServiceSelectionItem
): boolean =>
  servItem1.variant === servItem2.variant &&
  servItem1.parameters?.baggageWeight === servItem2.parameters?.baggageWeight &&
  servItem1.totalPrice?.amount === servItem2.totalPrice?.amount &&
  servItem1.ticketed === servItem2.ticketed;

const sortIds = {
  confirmed: 2,
  pendingPayment: 1,
  availableForSell: 0,
};

export const getGroupedServices = (
  allServices: FinnairPassengerServiceSelectionItem[] = [],
  tierLevel?: string,
  variantsWeCanSell: string[] = [],
  availableServices: FinnairPassengerServiceSelectionItem[] = [],
  isCarrierConnect = false,
  includeAssignAtAirportStatus = false
): GroupedServices[] => {
  const filteredAvailableServices = availableServices.filter((service) => {
    return !allServices.some(
      (s) => s.subCategory === service.subCategory || s.subCategory.toLowerCase().includes(service.subCategory)
    );
  });

  const servicesByKey = groupBy(allServices, (service) =>
    getServiceStatus(service, isCarrierConnect, includeAssignAtAirportStatus)
  );

  const services = Object.entries(servicesByKey).map(
    ([key, innerServices]) =>
      ({
        key,
        icon: getIconForService(innerServices[0], isCarrierConnect, includeAssignAtAirportStatus),
        services: consolidateServiceAccumulateQuantities(innerServices),
        showActionsButtons:
          key === ServiceStatus.PENDING_PAYMENT &&
          innerServices.some((s) => s.seatNumber || variantsWeCanSell.includes(s.variant)),
        tierLevel: tierLevel,
      }) as GroupedServices
  );

  const groupedServices =
    services.flatMap((s) => s.services.filter(isNotIncludedService)).length === 0 &&
    filteredAvailableServices.length > 0
      ? services.concat([
          {
            key: ServiceStatus.AVAILABLE_FOR_SELL,
            services: filteredAvailableServices,
            showActionsButtons: false,
            tierLevel: tierLevel,
          } as GroupedServices,
        ])
      : services;

  return groupedServices.sort((serviceA, serviceB) => sortIds[serviceB.key] - sortIds[serviceA.key]);
};

const consolidateServiceAccumulateQuantities = (
  services: FinnairPassengerServiceSelectionItem[]
): FinnairPassengerServiceSelectionItem[] => {
  return services.reduce((consolidatedServices, service) => {
    const consolidatedService = consolidatedServices.find((s) => isSameServiceItem(service, s));
    if (consolidatedService) {
      consolidatedService.quantity += service.quantity;
    } else {
      consolidatedServices.push({ ...service });
    }
    return consolidatedServices;
  }, []);
};

const mapPassenger = (bookingPassenger: FinnairPassengerItem): FinnairPassengerServiceItem => ({
  id: bookingPassenger.id,
  firstName: bookingPassenger.firstName,
  lastName: bookingPassenger.lastName,
  services: [],
});

const fulfillServicesBoundWithPassenger = (
  bookingBound: FinnairBoundItem,
  service: FinnairServiceItem,
  bookingPassengers: FinnairPassengerItem[]
): FinnairServiceBoundItem => {
  const existedServiceBound = service.bounds.find((bound) => bound.id == bookingBound.id);

  if (existedServiceBound) {
    return {
      ...existedServiceBound,
      segments: bookingBound.itinerary.filter(isFlight).map((itineraryItem) => {
        const foundItem = existedServiceBound.segments.find((segment) => segment.id === itineraryItem.id);

        if (foundItem) {
          return {
            ...foundItem,
            passengers: bookingPassengers.map((bookingPassenger) => {
              const existingPassenger = foundItem.passengers.find((passenger) => passenger.id === bookingPassenger.id);

              return existingPassenger || mapPassenger(bookingPassenger);
            }),
          };
        }
        return {
          arrival: itineraryItem.arrival,
          departure: itineraryItem.departure,
          id: itineraryItem.id,
          passengers: bookingPassengers.map(mapPassenger),
          quantity: 1,
          totalPrice: {},
        } as FinnairServiceSegmentItem;
      }),
    } as FinnairServiceBoundItem;
  } else {
    return {
      id: bookingBound.id,
      totalPrice: {},
      segments: bookingBound.itinerary.filter(isFlight).map((itineraryItem) => ({
        arrival: itineraryItem.arrival,
        departure: itineraryItem.departure,
        id: itineraryItem.id,
        passengers: bookingPassengers.map(mapPassenger),
        quantity: 1,
        totalPrice: {},
      })),
    } as FinnairServiceBoundItem;
  }
};

const upsellForCategory = (
  category: Category,
  bound: FinnairBoundItem,
  catalog: FinnairServiceCatalogV3 | undefined
) => {
  const categoriesWithUpsell = [
    Category.CABIN_BAGGAGE,
    Category.BAGGAGE,
    Category.MEAL,
    Category.SEAT,
    Category.WIFI,
    Category.SPORT,
    Category.LOUNGE,
    Category.PET,
  ] as Category[];

  const catalogForCategory = catalog?.categories.find((c) => c.category === category);
  const fragmentIds = isJourneyBasedCategory(category)
    ? [CustomServiceType.JOURNEY]
    : isBoundBasedCategory(category)
      ? [bound.id]
      : bound.itinerary.filter(isFlight).map((flight) => flight.id);

  return (
    categoriesWithUpsell.includes(category) &&
    fragmentIds.some(
      (fragmentId) =>
        !!catalogForCategory?.services[fragmentId] ||
        (catalogForCategory?.category === Category.SEAT && !!catalogForCategory?.lowestPrice[fragmentId])
    )
  );
};

const createEmptyServiceFromCatalog = (
  category: Category,
  bound: FinnairBoundItem,
  catalogForCategory: FinnairServiceCatalogItemV3
): FinnairServiceItem => {
  return {
    ...catalogForCategory,
    bounds: [{ id: bound.id, segments: [], totalPrice: null, quantity: 0 }],
    category,
    quantity: 0,
    title: catalogForCategory.translations.title,
  };
};

export const getLowestPrice = (
  lowestPrice: FinnairServiceCatalogItemV3['lowestPrice'],
  fragmentIds: string[]
): FinnairAmount | null => {
  if (!lowestPrice) {
    return null;
  }

  return fragmentIds.reduce((cheapest: FinnairAmount, fragmentId: string) => {
    const lowestPricesForPaxes = lowestPrice[fragmentId];

    if (!lowestPricesForPaxes || isEmptyObject(lowestPricesForPaxes)) {
      return cheapest;
    }

    return Object.values(lowestPricesForPaxes).reduce((innerCheapest: FinnairAmount, lowestPrice) => {
      if (!innerCheapest) {
        return lowestPrice.money;
      }

      if (+lowestPrice.money.amount < +innerCheapest.amount) {
        return lowestPrice.money;
      }

      return innerCheapest;
    }, null as FinnairAmount);
  }, null as FinnairAmount);
};

const getExpandUpsellService = (
  catalogForCategory: FinnairServiceCatalogItemV3,
  cartOrOrder: CartOrOrder,
  bound: FinnairBoundItem
): boolean => {
  if (![Category.SEAT, Category.CABIN_BAGGAGE, Category.BAGGAGE].includes(catalogForCategory.category)) {
    return false;
  }

  const seatMissing = passengersMissingCategoryService(Category.SEAT, cartOrOrder, bound);
  const cabinBagMissing = passengersMissingCategoryService(Category.CABIN_BAGGAGE, cartOrOrder, bound);
  const bagMissing = passengersMissingCategoryService(Category.BAGGAGE, cartOrOrder, bound);

  return (
    (catalogForCategory.category === Category.SEAT && seatMissing) ||
    (catalogForCategory.category == Category.CABIN_BAGGAGE && cabinBagMissing && !seatMissing) ||
    (catalogForCategory.category == Category.BAGGAGE && bagMissing && !cabinBagMissing && !seatMissing)
  );
};

const getUpsell = (
  cartOrOrder: CartOrOrder,
  catalogForCategory: FinnairServiceCatalogItemV3 | undefined,
  service: FinnairServiceItem,
  bound: FinnairBoundItem,
  shouldNotShowAddMoreBtn: boolean,
  products$: Observable<SmpProduct[]>
): Upsell | null => {
  if (!catalogForCategory) {
    return null;
  }

  const fragmentIds = isJourneyBasedCategory(catalogForCategory.category)
    ? [CustomServiceType.JOURNEY]
    : isBoundBasedCategory(catalogForCategory.category)
      ? [bound.id]
      : bound.itinerary.filter(isFlight).map((flight) => flight.id);
  const lowestPrice = getLowestPrice(catalogForCategory.lowestPrice, fragmentIds);

  if (!lowestPrice || +lowestPrice.amount === 0) {
    return null;
  }

  const hasServicesForCategory =
    combineServices([cartOrOrder.services.included, cartOrOrder.services.unpaid])
      ?.find((service) => service.category === catalogForCategory.category)
      ?.bounds.filter((b) => bound.id === b.id)
      .flatMap((b) => b.segments)
      .flatMap((segment) => segment.passengers).length > 0;

  const isShowMoreBtn =
    hasServicesForCategory &&
    !shouldNotShowAddMoreBtn &&
    [Category.BAGGAGE, Category.SPORT].includes(service.category) &&
    servicePresentedInServices(cartOrOrder.services, service.category, ['included', 'unpaid']);

  const updatingService$ = products$.pipe(
    map((products) => products.find((product) => product.category === catalogForCategory.category)),
    filter(Boolean),
    switchMap((product) => product.status$),
    map((status) => status === ButtonState.UPDATING),
    startWith(false)
  );

  /**
   * Custom hasPlusTierBenefit tier logic for LOUNGE
   */
  const hasPlusTierBenefit =
    service.category === Category.LOUNGE &&
    service.bounds
      .flatMap((bound) => bound.segments.flatMap((segment) => segment.passengers))
      .some((passenger) => {
        return (
          getPassengerTierLevel(passenger.id, cartOrOrder.passengers)?.toLowerCase().includes('gold') &&
          passenger.services.some((s) => s.includedInTierBenefit)
        );
      });

  return {
    articleKey: `MMB.fragments.upsell.${service.category}.allPax.url`,
    expand: getExpandUpsellService(catalogForCategory, cartOrOrder, bound),
    enabled: !hasServicesForCategory,
    lowestPrice,
    isShowMoreBtn,
    updatingService$,
    hasPlusTierBenefit,
  };
};

const isBoundFlownWithTimeDifference = (bound: FinnairBoundItem, diffInHours = 0) => {
  const flights = bound.itinerary.filter(isFlight);
  return (
    TzDate.now().hoursTo(TzDate.fromMillis(Date.parse(flights[flights.length - 1]?.arrival.dateTime))) - diffInHours <=
    0
  );
};

export const shouldWeUpsell = (
  category: Category,
  bound: FinnairBoundItem,
  catalog: FinnairServiceCatalogV3
): boolean => {
  return upsellForCategory(category, bound, catalog) && !isBoundFlownWithTimeDifference(bound);
};

const defaultServicesOrder = [
  Category.CABIN_BAGGAGE,
  Category.SEAT,
  Category.BAGGAGE,
  Category.MEAL,
  Category.SPORT,
  Category.WIFI,
  Category.PET,
  Category.LOUNGE,
  Category.PRIORITY,
  Category.COVER,
  Category.OTHER,
  Category.SPECIAL,
  Category.SPECIAL_NEED,
  Category.TRAVEL_COMFORT,
  Category.FIREARM,
  Category.MEDIC,
  Category.CHILD,
  Category.SAF,
];

export const findSegmentById = (segmentId: string, bound: FinnairBoundItem): FinnairItineraryItemFlight | undefined => {
  return bound.itinerary.filter(isFlight).find(({ id }) => id === segmentId);
};

export const findPassengerById = (paxId: string, order: FinnairOrder): FinnairPassengerItem | undefined => {
  return order.passengers.find((p) => p.id === paxId);
};

export const createEmptySeatServices = (
  servicesToShow: FinnairServiceItemWithIcon[],
  bound: FinnairBoundItem,
  requiredPassengerIds: string[] = [],
  order: FinnairOrder,
  seatsTitle: string
): FinnairServiceItemWithIcon[] => {
  const allSegmentIds: string[] = bound.itinerary
    .filter((i) => i.type === FinnairItineraryItemType.FLIGHT)
    .map((i) => (i as ItineraryItemFlight).id);

  const seats = servicesToShow.find((service) => service.category === Category.SEAT);
  const seatServicesBound = seats?.bounds?.find((b) => b.id === bound.id);
  const fakeEmptySeatService = [
    {
      ticketed: true,
      seatNumber: null,
      subCategory: SubCategory.SEAT,
    },
  ];
  if (!seats || !seatServicesBound) {
    // create seat empty services for whole bound
    return [
      ...servicesToShow,
      {
        bounds: [
          {
            id: bound.id,
            segments: allSegmentIds.map((segmentId) => {
              const segment = findSegmentById(segmentId, bound);
              return {
                id: segmentId,
                arrival: segment.arrival,
                departure: segment.departure,
                passengers: requiredPassengerIds
                  .map((paxId) => {
                    const pax = findPassengerById(paxId, order);
                    return pax
                      ? {
                          id: paxId,
                          firstName: pax.firstName,
                          lastName: pax.lastName,
                          services: fakeEmptySeatService,
                        }
                      : null;
                  })
                  .filter((pax) => pax !== null),
                quantity: requiredPassengerIds.length,
                totalPrice: {},
              } as FinnairServiceSegmentItem;
            }),
            totalPrice: {},
          } as FinnairServiceBoundItem,
        ],
        category: Category.SEAT,
        quantity: allSegmentIds.length * requiredPassengerIds.length,
        title: seatsTitle,
        icon: getIconForServiceCategory(Category.SEAT),
      },
    ].sort(
      (a, b) => order.services.servicesOrder.indexOf(a.category) - order.services.servicesOrder.indexOf(b.category)
    );
  } else if (seats && seatServicesBound) {
    // fill in segments items with empty seats for all passengers
    const missingSegmentIds = allSegmentIds.filter(
      (id) => !seatServicesBound.segments.find((segment) => segment.id === id)
    );
    seatServicesBound.segments = [
      ...seatServicesBound.segments,
      ...missingSegmentIds
        .map((segmentId) => {
          const segment = findSegmentById(segmentId, bound);
          if (!segment) {
            return null;
          }
          return {
            id: segmentId,
            arrival: segment.arrival,
            departure: segment.departure,
            passengers: requiredPassengerIds
              .map((paxId) => {
                const pax = findPassengerById(paxId, order);
                return pax
                  ? {
                      id: paxId,
                      firstName: pax.firstName,
                      lastName: pax.lastName,
                      services: fakeEmptySeatService,
                    }
                  : null;
              })
              .filter((pax) => pax !== null),
            quantity: requiredPassengerIds.length,
            totalPrice: {},
          } as FinnairServiceSegmentItem;
        })
        .filter(Boolean),
    ];

    // fill in missing passengers items (if passenger not found in segment)
    if (seatServicesBound?.segments) {
      seatServicesBound.segments = seatServicesBound?.segments?.map((segment) => {
        const missingPassengers = requiredPassengerIds
          .filter((id) => !segment.passengers?.some((passenger) => passenger.id === id))
          .map((paxId) => {
            const pax = findPassengerById(paxId, order);
            return pax
              ? {
                  id: paxId,
                  firstName: pax.firstName,
                  lastName: pax.lastName,
                  services: fakeEmptySeatService,
                }
              : null;
          })
          .filter((pax) => pax !== null) as FinnairPassengerServiceItem[];

        return {
          ...segment,
          passengers: [...segment.passengers, ...missingPassengers],
        };
      });
    }
    // fill in service items (if passenger.services has no seats)
    let segmentToPaxIdsMap = new Map<string, string[]>();
    if (seatServicesBound?.segments) {
      segmentToPaxIdsMap = seatServicesBound.segments.reduce((segmentMissingPaxIds, segment) => {
        const paxIdsMissingSeatServices = segment.passengers
          .filter((p) => p.services.length === 0)
          .map((p) => p.id)
          .filter((paxId) => requiredPassengerIds.includes(paxId));

        if (paxIdsMissingSeatServices.length > 0) {
          segmentMissingPaxIds.set(segment.id, paxIdsMissingSeatServices);
        }

        return segmentMissingPaxIds;
      }, segmentToPaxIdsMap);
    }
    const filledInSeatServices = {
      ...seats,
      bounds: seats.bounds.map((b) => {
        return b.id === bound.id
          ? {
              ...seatServicesBound,
              segments: seatServicesBound.segments.map((segment) => {
                return {
                  ...segment,
                  passengers: segment.passengers.map((passenger) => {
                    return {
                      ...passenger,
                      services: segmentToPaxIdsMap.get(segment.id)?.includes(passenger.id)
                        ? [
                            {
                              ticketed: true,
                              seatNumber: null,
                            },
                          ]
                        : passenger.services,
                    };
                  }),
                };
              }),
            }
          : b;
      }),
    };
    return servicesToShow.map((service) => {
      return service.category === Category.SEAT ? filledInSeatServices : service;
    }) as FinnairServiceItemWithIcon[];
  }
};

export const getServicesForBoundWithUpsell = (
  cartOrOrder: CartOrOrder,
  showUpsell: boolean,
  servicesToShow: FinnairServiceItem[],
  servicesOrder: Category[] = defaultServicesOrder,
  bound: FinnairBoundItem,
  catalog: FinnairServiceCatalogV3 | undefined,
  products$: Observable<SmpProduct[]> = of([])
): FinnairServiceItemWithUpsell[] => {
  if (!showUpsell) {
    return servicesToShow
      .map((service) => {
        return {
          ...service,
          bounds: service.bounds.filter((b) => b.id === bound.id),
        };
      })
      .filter((s) => s.bounds.length > 0)
      .sort((a, b) => servicesOrder.indexOf(a.category) - servicesOrder.indexOf(b.category));
  }

  return servicesOrder
    .filter((category) => {
      const showService = servicesToShow.some(
        (service) => service.category === category && service.bounds.some((b) => b.id === bound.id)
      );
      return showService || shouldWeUpsell(category, bound, catalog) || category === Category.SPECIAL_NEED;
    })
    .map((category) => {
      const shouldWeUpsellForThisCategory = shouldWeUpsell(category, bound, catalog);
      const catalogForCategory = shouldWeUpsellForThisCategory
        ? catalog.categories.find((item) => item.category === category)
        : null;
      const existingService =
        servicesToShow.find((s) => s.category === category) ??
        (catalogForCategory ? createEmptyServiceFromCatalog(category, bound, catalogForCategory) : null);

      const bounds: FinnairServiceBoundItem[] = existingService
        ? [fulfillServicesBoundWithPassenger(bound, existingService, cartOrOrder.passengers)]
        : (existingService?.bounds ?? []);

      if (existingService) {
        const shouldNotShowAddMoreBtn = bounds.some((b) =>
          b.segments.some((s) => s.passengers.some((p) => p.services.length === 0))
        );

        const upsell = shouldWeUpsellForThisCategory
          ? getUpsell(cartOrOrder, catalogForCategory, existingService, bound, shouldNotShowAddMoreBtn, products$)
          : null;

        return {
          ...existingService,
          bounds,
          upsell,
        };
      }

      if (
        category === Category.SPECIAL_NEED &&
        !isBoundFlownWithTimeDifference(bound, 48) &&
        bound.itinerary
          .filter(isFlight)
          .some((flight) => isFinnairNorraOrWetLease(flight.operatingAirline?.name, flight.operatingAirline?.code))
      ) {
        return {
          bounds: [{ id: bound.id, segments: [], totalPrice: null, quantity: 0 }],
          category,
          quantity: 0,
          title: '',
          upsell: {
            enabled: true,
            lowestPrice: null,
            isShowMoreBtn: servicePresentedInServices(cartOrOrder.services, category, ['included', 'unpaid']),
            articleKey: `MMB.fragments.upsell.${category}.allPax.url`,
            expand: false,
            updatingService$: of(false),
            hasPlusTierBenefit: false,
          },
        };
      }

      return null;
    })
    .filter(Boolean)
    .filter((s) => s.bounds.length > 0);
};

export const isBoundAPISRequired = (order: FinnairOrder, bound: FinnairBoundItem): boolean => {
  return !!order.eligibilities?.checkIn?.find(
    (eligibility) => eligibility.id === bound.id && eligibility.isApisRequired
  );
};

export const isOrder = (order: FinnairCart | FinnairOrder): boolean => {
  return order.id.length === 6;
};
