import { UntypedFormGroup, ValidatorFn } from '@angular/forms';

import { LocalDate, pad, TzDate } from '@fcom/core/utils';
import { BirthDate } from '@fcom/dapi/interfaces';

import { FlightTimeOption } from './../interfaces/flight-filters.interface';

export const formatDate = (day?: string, month?: string, year?: string): string => {
  if (year && month && day) {
    const dateString = `${pad(year, 4)}-${pad(month)}-${pad(day)}`;
    if (LocalDate.isLocalDate(dateString)) {
      return dateString;
    }
  }
  return '';
};

export const formatDateOfBirth = (birthDate: BirthDate): string => {
  return formatDate(birthDate?.dateOfBirth, birthDate?.monthOfBirth, birthDate?.yearOfBirth);
};

export function passportExpiryValidator(): ValidatorFn {
  return (group: UntypedFormGroup): { [key: string]: any } | null => {
    const day = group.controls['day'].value;
    const month = group.controls['month'].value;
    const year = group.controls['year'].value;

    if (!day && !month && !year) return null;
    if (!day || !month || !year) return { selectAll: 'Select day, month and year' };

    const tomorrow = LocalDate.now().plusDays(1);
    const expiryDate = LocalDate.forDate(year, month, day);

    if (expiryDate <= tomorrow) return { invalidDate: 'Invalid passport expiry date' };
  };
}

export const formatToDateOfDateString = (
  inputDate: string | LocalDate
): { day: string; month: string; year: string } | null => {
  const date = inputDate instanceof LocalDate ? inputDate.toString() : inputDate;
  if (!date || !date.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)) {
    return null;
  }
  const [year, month, day] = date.split('-').map((d) => parseInt(d, 10).toString());
  return { year, month, day };
};

export const formatToDateOfBirth = (birthDate: string | LocalDate): BirthDate | null => {
  const date = formatToDateOfDateString(birthDate);

  if (!date) {
    return null;
  }

  return { yearOfBirth: date.year, monthOfBirth: date.month, dateOfBirth: date.day };
};

export const isBetweenHours = (date: TzDate, startHour: number, endHour: number): boolean => {
  const dateHour = date.localHours;

  return dateHour >= startHour && dateHour < endHour;
};

export const shouldTimeBeKept = (timeOptions: FlightTimeOption[], dateTime: string): boolean =>
  (timeOptions.includes(FlightTimeOption.MORNING) && isBetweenHours(new TzDate(dateTime), 0, 12)) ||
  (timeOptions.includes(FlightTimeOption.AFTERNOON) && isBetweenHours(new TzDate(dateTime), 12, 17)) ||
  (timeOptions.includes(FlightTimeOption.EVENING) && isBetweenHours(new TzDate(dateTime), 17, 24));

export const isDate = (value: string | Date): boolean => {
  return !isNaN(new Date(value).getTime());
};

export const toISOStringPreserveTimeZone = (value: string): string => {
  return new Date(value + ' GMT').toISOString();
};
