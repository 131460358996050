<ng-template #showIcon>
  <fcom-icon
    *ngIf="icon; else returnBtn"
    [ngClass]="fullScreen ? 'icon-large' : 'icon-medium'"
    [name]="icon"
  ></fcom-icon>
</ng-template>
<ng-template #returnBtn>
  <button
    [attr.aria-label]="'actions.back' | finLocalization"
    [attr.title]="'actions.back' | finLocalization"
    (click)="returnClicked.emit($event)"
  >
    <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.ARROW_LEFT"></fcom-icon>
  </button>
</ng-template>

<div
  [style.height]="availableHeight < loginContainer?.clientHeight ? availableHeight - bottomMargin + 'px' : 'auto'"
  [style.overflow]="availableHeight < loginContainer?.clientHeight ? 'auto' : 'unset'"
  class="login-dialog login-dialog-open z5"
  [class.overlay]="overlay && !fullScreen"
  role="dialog"
  data-testid="login-dialog-container"
>
  <div
    #loginContainer
    [class.grow]="fullScreen"
    [ngClass]="{ 'rounded-large shadow': !fullScreen }"
    class="content-container scroll"
    clickOutsideException="fin-login-form-container"
    (fcomClickOutside)="closeClicked.emit($event)"
    data-testid="login-form-container-content-root"
  >
    <div
      class="dialog-header white-bg ps-medium-x ps-medium-t ps-xsmall-b align-left"
      [ngClass]="{ 'nordic-blue-900-bg white-text': invertHeaderColors }"
    >
      <div>
        <fcom-stepper
          *ngIf="stepperSteps$ | async as steps; else showIcon"
          class="inline-block"
          [showLabels]="false"
          [steps]="steps"
          [theme]="StepperTheme.DARK"
          [activeStep]="stepperActiveStep$ | async"
          (clicked)="stepperClicked.emit($event)"
        ></fcom-stepper>
        <button
          class="float-right"
          [class.nordic-blue-900-text]="!invertHeaderColors"
          [attr.aria-label]="'actions.close' | finLocalization"
          [attr.title]="'actions.close' | finLocalization"
          (click)="closeClicked.emit($event)"
        >
          <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.CLOSE_DELETE"></fcom-icon>
        </button>
      </div>

      <h1 [ngClass]="fullScreen ? 'font-heading-2' : 'font-heading-4'" class="ps-medium-t" #dialogTitle tabindex="-1">
        {{ title | finLocalization }}
      </h1>

      <div aria-live="assertive">
        <fcom-notification *ngIf="error" class="mr-small-t" [theme]="NotificationTheme.ALERT">
          <span class="font-body-1">{{ error | finLocalization }}</span>
        </fcom-notification>
      </div>

      <p class="font-body-1 ms-large-t" [class.ms-medium-b]="!compact">
        {{ description | finLocalization }}
      </p>

      <ng-content select=".header-content"></ng-content>
    </div>
    <div class="dialog-content white-bg align-left" [ngClass]="{ 'ps-medium-x ps-medium-b ps-xsmall-t': !compact }">
      <ng-content></ng-content>
    </div>
  </div>
</div>
