export const onOpenGtmEvent = 'basic-info';

export interface CheckinInputData {
  familyName: string;
  bookingRef?: string;
  eTicketNumber?: string;
}

export type JoinFinnairPlusRequestData = {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
  gender: string;
  phoneNumber?: string;
  password?: string;
  /** Format: language | language-COUNTRYCODE */
  locale: string;
  countryCode: string;
  agreements?: boolean;
  enrollmentChannel: string;
  marketingPermissions?: Record<string, boolean>;
  consent?: boolean;
  guardianName?: string;
  guardianEmail?: string;
  guardianPhoneNumber?: string;
  guardianMemberNumber?: string;
};

export type JoinFinnairPlusResponseData = {
  memberNumber: string;
  status: {
    message: StatusCode;
  };
};

export enum StatusCode {
  OK = 'OK',
  ERROR = 'ERROR',
}

export type JoinFinnairPlusErrorResponseData = {
  reason?: string;
  status: {
    message?: StatusCode;
    fieldErrors?: { errorMessage: string }[];
  };
};
