import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes, UrlMatchResult, UrlSegment } from '@angular/router';
import { CommonModule } from '@angular/common';

import { UiTranslateModule } from '@fcom/ui-translate';
import { IconsModule } from '@fcom/ui-components';
import { CmsFooterComponent, CmsModule, DynamicRouteComponent } from '@fcom/cms/components';
import {
  ClientOnlyComponent,
  ComponentsModule,
  ErrorComponent,
  FrontPageComponent,
  HeaderComponent,
  NavigationModule,
  SelectLanguageComponent,
} from '@fcom/common/components';
import { RootPaths, ROUTER_LANG_PARAM, AppPreloadingStrategyService } from '@fcom/core';
import { LoginModule, MarketingPreferencesComponent } from '@fcom/common/login';
import { FeedbackWidgetModule } from '@fcom/common/components/feedback-widget/feedback-widget.module';
import { BaseLayoutComponent, BaseLayoutModule } from '@fcom/common/components/base-layout';
import { DirectiveModule, FragmentService, PipesModule } from '@fcom/common';
import { CampaignBannerComponent } from '@fcom/common/components/header/campaign-banner/campaign-banner.component';
import { InstantsearchApiModule } from '@fcom/dapi/api/instantsearch-api.module';

import { LanguageSettingLayoutComponent } from './language-setting-layout.component';
import { LanguageGuard } from './lang.guard';
import { LanguageSettingRootComponent } from './language-setting-root.component';
import { LanguageSettingLayoutModule } from './language-setting-layout.module';
import { AppComponent } from './app.component';
import { environment } from '../../../environments/environment';

const VOLUNTARY_CANCEL_LENGTH = 2;
const INVOLUNTARY_CANCEL_LENGTH = 3;

export const CMS_ROUTES: Route[] = [
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      { path: '', component: CampaignBannerComponent, outlet: 'campaign-banner' },
      { path: '', component: HeaderComponent, outlet: 'header' },
      { path: '', component: FrontPageComponent },
      { path: '**', component: DynamicRouteComponent },
      { path: '', component: CmsFooterComponent, outlet: 'footer' },
    ],
  },
];

export const selectLangComponent = {
  path: '',
  component: SelectLanguageComponent,
};

export const routes: Routes = [
  {
    path: '',
    component: LanguageSettingLayoutComponent,
    children: [selectLangComponent],
  },
  {
    path: ':lang/sorry/:error',
    component: LanguageSettingRootComponent,
    children: [
      {
        path: '',
        component: BaseLayoutComponent,
        children: [
          { path: '', component: HeaderComponent, outlet: 'header' },
          { path: '', component: ErrorComponent },
          { path: '', component: CmsFooterComponent, outlet: 'footer' },
        ],
      },
    ],
  },
  {
    path: RootPaths.SELECT_LANGUAGE_ROOT,
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'devtool',
    canMatch: [() => environment.enableDevToolPath],
    loadChildren: () =>
      import(/* webpackChunkName: "devtool" */ '../../devtool/devtool.module').then((m) => m.DevtoolModule),
  },
  {
    path: `:${ROUTER_LANG_PARAM}`,
    component: LanguageSettingRootComponent,
    canActivateChild: [LanguageGuard],
    children: [
      {
        path: RootPaths.BOOKING_ROOT,
        loadChildren: () =>
          import(/* webpackChunkName: "booking" */ '../../booking/booking.module').then((m) => m.BookingModule),
        data: { preload: true },
      },
      {
        path: RootPaths.MANAGE_BOOKING_ROOT,
        loadChildren: () =>
          import(/* webpackChunkName: "manage-booking" */ '../../manage-booking/manage-booking.module').then(
            (m) => m.ManageBookingModule
          ),
      },
      {
        path: RootPaths.DISRUPTION_ROOT,
        loadChildren: () =>
          import(/* webpackChunkName: "disruption" */ '../../disruption/disruption.module').then(
            (m) => m.DisruptionModule
          ),
      },
      {
        matcher: (url: UrlSegment[]): UrlMatchResult => {
          const fullPath = url.join('/');

          if (fullPath.includes(RootPaths.CANCEL_ROOT)) {
            return { consumed: url.slice(0, VOLUNTARY_CANCEL_LENGTH) };
          } else if (fullPath.includes(RootPaths.INVOLUNTARY_CANCEL_ROOT)) {
            return { consumed: url.slice(0, INVOLUNTARY_CANCEL_LENGTH) };
          }
          return null;
        },
        loadChildren: () =>
          import(/* webpackChunkName: "cancel-booking" */ '../../cancel/cancel.module').then((m) => m.CancelModule),
      },
      {
        path: RootPaths.CHECK_IN_ROOT,
        loadChildren: () =>
          import(/* webpackChunkName: "manage-booking" */ '../../manage-booking/manage-booking.module').then(
            (m) => m.ManageBookingModule
          ),
      },
      {
        path: RootPaths.SITE_SEARCH_ROOT,
        loadChildren: () =>
          import(/* webpackChunkName: "site-search" */ '../../site-search/site-search.module').then(
            (m) => m.SiteSearchModule
          ),
      },
      {
        path: RootPaths.ONE_UPGRADE_ROOT,
        loadChildren: () =>
          import(/* webpackChunkName: "one-upgrade" */ '../../one-upgrade/one-upgrade.module').then(
            (m) => m.OneUpgradeModule
          ),
      },
      {
        path: RootPaths.REACCOMMODATION_ROOT,
        loadChildren: () =>
          import(/* webpackChunkName: "reaccommodation" */ '../../reaccommodation/reaccommodation.module').then(
            (m) => m.ReaccommodationModule
          ),
      },
      {
        path: RootPaths.OND_MARKETING_LANDING_ROOT,
        loadChildren: () =>
          import(
            /* webpackChunkName: "ond-marketing-landing" */ '../../ond-marketing-landing/ond-marketing-landing.module'
          ).then((m) => m.OndMarketingLandingModule),
      },
      {
        path: RootPaths.PLUS_ROOT,
        loadChildren: () =>
          import(/* webpackChunkName: "plus" */ '../../loyalty-plus/plus.module').then((m) => m.PlusModule),
      },
      {
        path: RootPaths.VOLUNTARY_CHANGE_ROOT,
        loadChildren: () =>
          import(/* webpackChunkName: "voluntary-change" */ '../../voluntary-change/voluntary-change.module').then(
            (m) => m.VoluntaryChangeModule
          ),
      },
      {
        path: RootPaths.TIMETABLE_ROOT,
        loadChildren: () =>
          import(/* webpackChunkName: "timetable" */ '../../timetable/timetable.module').then((m) => m.TimetableModule),
      },
      {
        path: RootPaths.REFUNDS,
        loadChildren: () =>
          import(/* webpackChunkName: "refunds" */ '../../refunds/refunds.module').then((m) => m.RefundsModule),
      },
      {
        path: RootPaths.CORPORATE,
        loadChildren: () =>
          import(/* webpackChunkName: "corporate" */ '../../corporate/corporate.module').then((m) => m.CorporateModule),
      },
      {
        path: RootPaths.TRAVEL_READY,
        loadChildren: () =>
          import(/* webpackChunkName: "travel-ready" */ '../../travel-ready/travel-ready.module').then(
            (m) => m.TravelReadyModule
          ),
      },
      {
        path: RootPaths.FINNAIR_HOLIDAYS,
        component: BaseLayoutComponent,
        data: {
          tab: 'am',
        },
        children: [
          { path: '', component: HeaderComponent, outlet: 'header' },
          { path: '', component: FrontPageComponent },
          { path: '**', component: DynamicRouteComponent },
          { path: '', component: CmsFooterComponent, outlet: 'footer' },
        ],
      },
      {
        path: '',
        component: ClientOnlyComponent,
        outlet: 'marketingPreferences',
        data: {
          componentType: MarketingPreferencesComponent,
        },
      },
      ...CMS_ROUTES,
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: AppPreloadingStrategyService,
      initialNavigation: 'enabledNonBlocking',
      anchorScrolling: 'enabled',
    }),

    BaseLayoutModule,
    LanguageSettingLayoutModule,
    CommonModule,

    // Application modules
    ComponentsModule,
    CmsModule.forRoot(),
    FeedbackWidgetModule,
    LoginModule,
    NavigationModule,
    PipesModule,
    DirectiveModule,
    UiTranslateModule,
    IconsModule,
    InstantsearchApiModule,
  ],
  declarations: [AppComponent, LanguageSettingRootComponent, SelectLanguageComponent, FrontPageComponent],
  providers: [LanguageGuard, AppPreloadingStrategyService, FragmentService],
  exports: [AppComponent, LanguageSettingRootComponent],
})
export class RouteModule {}
